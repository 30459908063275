import styled from "styled-components";

export const DiscountedCardWrapper = styled.div`
  display: flex;
  gap: 32px;
  justify-content: center;
  width: 100%;
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;
export const LeftDiscountCard = styled.div`
  flex: 1;
`;
export const DiscountCardImage = styled.div`
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 1); /* Creates a deeper shadow */
  border-radius: 12px;
  position: relative;
  height: 70vh;
  width: 88%;
  max-width: 650px;
  margin: 0px 42px;
  cursor: pointer;
  img {
    height: 100%;
    width: 100%;
    border-radius: 12px;
  }
  @media (max-width: 768px) {
    max-width: 250px;
  }
  @media (max-width: 1410px) {
    width: 85%;
  }
`;
export const RightDiscountCard = styled.div`
  display: flex;
  gap: 32px;
  flex-wrap: wrap;
  position: relative;
  flex: 1;
`;

export const RightImage = styled.div`
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.5); /* Creates a deeper shadow */
  border-radius: 12px;
  height: 33.2vh;
  width: 50%;
  max-width: 320px;
  position: relative;
  cursor: pointer;
  img {
    height: 100%;
    width: 100%;
    border-radius: 12px;
  }
  @media (max-width: 1410px) {
    width: 46%;
  }
`;

export const DiscountTitle = styled.div`
  position: absolute;
  top: -40px;
`;

export const DiscountFooter = styled.div`
  position: absolute;
  bottom: 0px;
`;
