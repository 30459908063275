import React from "react";
import { OfferingsWrapper, StyledOffering } from "./OfferingsStyles";
import { CiBoxes } from "react-icons/ci";
import { GiOrange } from "react-icons/gi";
import { StyledMedium, StyledSmall } from "../../styles/CommonStyles";
import { screenWidth } from "../utilities";

const Offerings = () => {
  const screen = screenWidth();
  const OFFERINGS = [
    {
      icon: <CiBoxes />,
      title: "Buy from biggest wholesellers",
    },
    {
      icon: <GiOrange />,
      title:
        "Buy anything and everything. (Dogs and Cats, Automobile, Home Decor, Clothing...)",
    },
    {
      icon: <GiOrange />,
      title: "Deals in Corporate Gifting and Bulk Orders",
    },
    {
      icon: <GiOrange />,
      title: "Boost your business with our platform.",
    },
  ];
  return (
    <OfferingsWrapper>
      {OFFERINGS?.map((offering, index) => {
        if (screen == "mobile" && index == 3) {
          return (
            <StyledOffering>
              {offering?.icon}
              <StyledSmall>{offering?.title}</StyledSmall>
            </StyledOffering>
          );
        } else if (screen != "mobile") {
          return (
            <StyledOffering>
              {offering?.icon}
              <StyledSmall>{offering?.title}</StyledSmall>
            </StyledOffering>
          );
        }
      })}
    </OfferingsWrapper>
  );
};

export default Offerings;
