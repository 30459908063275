import React from "react";
import { ClientSecondFoldWrapper } from "./ClientSecondFoldStyles";
import ClientOfferings from "../ClientOfferings/ClientOfferings";
import Categories from "../Categories/Categories";

const ClientSecondFold = ({ page_type, client }) => {
  return (
    <ClientSecondFoldWrapper>
      <ClientOfferings client={client} />
    </ClientSecondFoldWrapper>
  );
};

export default ClientSecondFold;
