import styled from "styled-components";

export const TestimonialWrapper = styled.div`
  background: #ffe4c4;
  height: 300px;
  width: 300px;
  position: relative;
  border-radius: 24px;
  margin-top: 200px;
  margin-left: 200px;
  margin-bottom: 64px;
  @media (max-width: 768px) {
    margin-left: 60%;
  }
`;

export const ImgWrapper = styled.div`
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  img {
    height: 150px;
    border-radius: 24px;
    width: 150px;
  }
`;

export const TextContainer = styled.div`
  padding: 100px 50px;
  height: 200px;
  &:nth-child(1) {
    text-align: center;
  }
`;

export const DescriptionContainer = styled.div`
  height: 140px;
  overflow-y: scroll;
`;
