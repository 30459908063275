import React, { useState, useEffect } from "react";
import {
  CTAIconsContainer,
  CompanyInfoContainer,
  LogoContainer,
  NavChild,
  NavContent,
  NavItem,
  NavbarWrapper,
  SearchContent,
  SearchResultsContainer,
} from "./NavbarStyles";
import company_logo from "@/assets/company_logo.png";
import { StyledMedium } from "@/styles/CommonStyles";
import { IoCartOutline } from "react-icons/io5";
import { IoMdHeartEmpty } from "react-icons/io";
import { FiFacebook } from "react-icons/fi";
import { FaInstagram } from "react-icons/fa6";
import { AiOutlineYoutube } from "react-icons/ai";
import { GoSearch } from "react-icons/go";
import InputBox from "../common/InputBox/InputBox";
import { useDispatch } from "react-redux";
import types from "@/redux/types";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { screenWidth } from "../utilities";
import { RxHamburgerMenu } from "react-icons/rx";
import { RxCross2 } from "react-icons/rx";
import Sidebar from "./Sidebar";

const Navbar = ({ page_type }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const screen = screenWidth();

  const [navbarData, setNavbarData] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const [navbarToggle, setNavbarToggle] = useState(false);
  const fetchNavbar = async () => {
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_ORANGE_URL}/v1/home/navbar`
      );
      console.log("result101", result);
      setNavbarData(result?.data?.data);
    } catch (error) {
      return;
      // return <FallbackUI />;
    }
  };

  useEffect(() => {
    fetchNavbar();
  }, []);

  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(context, args), wait);
    };
  }

  const handleSearch = async (e) => {
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_ORANGE_URL}/v1/search?keyword=${e.target.value}`
      );
      setSearchResult(result?.data?.data);
    } catch (error) {
      return;
      // return <FallbackUI />;
    }
  };

  if (screen != "mobile") {
    return (
      <NavbarWrapper
        style={{ background: page_type == "services" ? "white" : "#212121" }}
      >
        <NavChild>
          <LogoContainer onClick={() => navigate("/")}>
            <img src={company_logo} alt="company logo" />
          </LogoContainer>

          <NavContent>
            {navbarData?.map((navData, index) => {
              if (index < 5) {
                return (
                  <NavItem
                    onMouseOver={() =>
                      dispatch({
                        type: types.SET_NAV_CONTENT,
                        payload: {
                          menu: navData,
                          showMenu: true,
                        },
                      })
                    }
                    onMouseOut={() =>
                      dispatch({
                        type: types.SET_NAV_CONTENT,
                        payload: {
                          menu: navData,
                          showMenu: false,
                        },
                      })
                    }
                  >
                    <StyledMedium
                      style={{
                        color: page_type == "services" ? "#212121" : "white",
                        cursor: "pointer",
                      }}
                    >
                      {navData?.display_name}
                    </StyledMedium>
                  </NavItem>
                );
              }
            })}
          </NavContent>
        </NavChild>

        <NavChild className="direction">
          <CTAIconsContainer>
            <CompanyInfoContainer className="align-social-icons">
              <FaInstagram
                size={24}
                color={page_type == "services" ? "#212121" : "white"}
              />
              <AiOutlineYoutube
                size={24}
                color={page_type == "services" ? "#212121" : "white"}
              />
              <FiFacebook
                size={24}
                color={page_type == "services" ? "#212121" : "white"}
              />
            </CompanyInfoContainer>
            <CompanyInfoContainer>
              <IoCartOutline
                size={24}
                color={page_type == "services" ? "#212121" : "white"}
              />
              <IoMdHeartEmpty
                size={24}
                color={page_type == "services" ? "#212121" : "white"}
              />
            </CompanyInfoContainer>
          </CTAIconsContainer>

          <div style={{ position: "relative" }}>
            <InputBox
              placeholder="Looking for..."
              icon={<GoSearch color="white" size={18} />}
              type="text"
              styles={{
                background: page_type == "services" ? "#212121" : "white",
                padding: "12px 24px",
                color: page_type == "services" ? "white" : "#212121",
              }}
              onChange={debounce(handleSearch, 400)}
            />
            <SearchResultsContainer
              style={{ display: searchResult?.length > 0 ? "block" : "none" }}
            >
              {searchResult?.map((content) => {
                console.log("content123", content);
                return (
                  <SearchContent
                    onClick={() =>
                      navigate(
                        `/category/products?parent_category_name=${content?.parent_category_name}&category_id=${content.category_id}`
                      )
                    }
                  >
                    {content.category_name}
                  </SearchContent>
                );
              })}
            </SearchResultsContainer>
          </div>
        </NavChild>
      </NavbarWrapper>
    );
  } else {
    return (
      <>
        <NavbarWrapper
          style={{ background: page_type == "services" ? "white" : "#212121" }}
        >
          <LogoContainer onClick={() => navigate("/")}>
            <img src={company_logo} alt="company logo" />
          </LogoContainer>

          {navbarToggle ? (
            <RxCross2
              size={30}
              color="white"
              style={{ position: "relative", right: "24px" }}
              onClick={() => setNavbarToggle(!navbarToggle)}
            />
          ) : (
            <RxHamburgerMenu
              size={30}
              color="white"
              style={{ position: "relative", right: "24px" }}
              onClick={() => setNavbarToggle(!navbarToggle)}
            />
          )}
        </NavbarWrapper>

        {navbarToggle ? <Sidebar navbarData={navbarData} /> : null}
      </>
    );
  }
};

export default Navbar;
