import styled from "styled-components";

export const InputContainer = styled.div`
  position: relative;
`;

export const IconContainer = styled.div(({ right, top }) => ({
  position: "absolute",
  right: right ?? "12px",
  top: top ?? "6px",
  background: "black",
  borderRadius: "50%",
  width: "32px",
  height: "30px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

export const Input = styled.input(
  ({
    background,
    color,
    padding,
    minWidth,
    maxWidth,
    borderBottom,
    borderRadius,
  }) => ({
    padding: padding,
    borderRadius: borderRadius ?? "24px",
    minWidth: minWidth ?? "500px",
    maxWidth: maxWidth ?? "400px",
    background: background,
    border: "none",
    borderBottom: borderBottom ?? "none",
    outline: "none",
    fontSize: "16px",
    color: color,
  })
);
