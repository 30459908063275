import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 32px 0px;
  gap: 14px;
  align-items: center;
`;

export const StyledMedium = styled.div`
  font-size: 16px;
  letter-spacing: 0.8px;
  color: #101820ff;
  &.hoverClass:hover {
    color: #ff6110;
    cursor: pointer;
    text-decoration: underline;
  }

  &.primary {
    color: white;
    cursor: initial;
    text-decoration: underline;
  }

  &.padding {
    padding: 12px 0px;
  }
`;

export const Styled2XL = styled.div`
  font-size: 84px;
  letter-spacing: 0.8px;
  color: #101820ff;
  &.hoverClass:hover {
    color: #ff6110;
    cursor: pointer;
    text-decoration: underline;
  }

  &.primary {
    color: white;
    cursor: initial;
    text-decoration: underline;
  }

  &.padding {
    padding: 12px 0px;
  }
`;

export const StyledXLPlus = styled.div`
  font-size: 44px;
  letter-spacing: 0.8px;
  color: #101820ff;
  &.hoverClass:hover {
    color: #ff6110;
    cursor: pointer;
    text-decoration: underline;
  }

  &.primary {
    color: white;
    cursor: initial;
    text-decoration: underline;
  }

  &.padding {
    padding: 12px 0px;
  }
`;

export const StyledSmall = styled.div`
  font-size: 14px;
  letter-spacing: 0.8px;
  font-weight: 400;
  color: #101820ff;
  &.hoverClass:hover {
    color: #ff6110;
    cursor: pointer;
    text-decoration: underline;
  }
  &.primary {
    color: white;
    text-decoration: none;
    cursor: initial;
  }
`;

export const StyledXL = styled.div`
  font-size: 30px;
  letter-spacing: 0.8px;
  font-weight: 400;

  &.hoverClass:hover {
    color: #ff6110;
    cursor: pointer;
    text-decoration: underline;
  }
  &.primary {
    color: white;
    text-decoration: none;
    cursor: initial;
  }
`;

export const Styled6XL = styled.div`
  font-size: 112px;
  letter-spacing: 0.8px;
  font-weight: 900;

  &.hoverClass:hover {
    color: #ff6110;
    cursor: pointer;
    text-decoration: underline;
  }
  &.primary {
    color: white;
    text-decoration: none;
    cursor: initial;
  }
`;

export const Container = styled.div`
  &.dFlex {
    display: flex;
    align-items: center;
    gap: 24px;
  }

  &.justify-between {
    justify-content: space-between;
  }

  &.justify-around {
    justify-content: space-around;
  }

  &.justify-center {
    justify-content: space-center;
  }

  &.justify-around {
    justify-content: space-around;
  }

  &.align-start {
    align-items: flex-start;
  }

  &.gap {
    gap: 12px;
  }

  &.flexWrap {
    flex-wrap: wrap;
  }

  &.mega-menu {
    padding: 24px 36px;
    position: relative;
  }

  &.position-absolute {
    position: absolute;
    top: 10%;
  }

  &.shadow-card {
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
    z-index: 2;
    background: white;
  }

  &.maxWidth {
    width: 200px;
  }

  &.bannerFlex {
    display: flex;
    flex-direction: column;
    gap: 4px;
    justify-content: center;
    align-items: center;
  }

  &.bannerShade {
    padding: 16px;
  }

  &.marginTop {
    margin-top: 4px;
  }
`;

export const Image = styled.img`
  &.megamenu {
    width: 40px;
    height: 40px;
  }
`;

export const Input = styled.input`
  border-bottom: 2px solid #ff6110;
  outline: 0;
`;

export const StyledCard = styled.div(({ image }) => ({
  height: "200px",
  width: "200px",
  backgroundImage: `url(${image})`,
  backgroundSize: "cover",
  position: "relative",
}));

export const CardTitle = styled.div(({}) => ({
  position: "absolute",
  top: "50%",
  left: "30%",
}));
