import petshop from "@/assets/petshop.png";
import home_decor from "@/assets/home_decor.jpg";
import automobile from "@/assets/automobile.jpg";
import car_store from "@/assets/car_store.jpg";
// import car_video from "@/assets/car_video.mp4";
import clothing_photo from "@/assets/clothing_photo.jpg";
// import clothing_video from "@/assets/clothing_video.mp4";
// import pet_dog_video from "@/assets/pet_dog_video.mp4";
import pet_banner from "@/assets/pet_banner.jpg";

export const NAV_CONTENT = [
  {
    id: 1,
    title: "Appliances",
    link: "/products",
    products: [
      {
        id: 1,
        title: "Air Fryers",
        link: "/air-fryers",
        icon: "https://m.media-amazon.com/images/I/61l4MmL-WJL._SX679_.jpg",
      },
      {
        id: 2,
        title: "Electric Kettles",
        link: "/electric-kettles",
        icon: "https://m.media-amazon.com/images/I/61l4MmL-WJL._SX679_.jpg",
      },
      {
        id: 3,
        title: "Mixer Grinders",
        link: "/mixer-grinders",
        icon: "https://m.media-amazon.com/images/I/61l4MmL-WJL._SX679_.jpg",
      },
      {
        id: 4,
        title: "Oven",
        link: "/oven",
        icon: "https://m.media-amazon.com/images/I/61l4MmL-WJL._SX679_.jpg",
      },
      {
        id: 5,
        title: "Rice Maker",
        link: "/oven",
        icon: "https://m.media-amazon.com/images/I/61l4MmL-WJL._SX679_.jpg",
      },
      {
        id: 6,
        title: "Coffee Mugs",
        link: "/mugs",
        icon: "https://m.media-amazon.com/images/I/61l4MmL-WJL._SX679_.jpg",
      },
      {
        id: 7,
        title: "Electric Kettles",
        link: "/electric-kettles",
        icon: "https://m.media-amazon.com/images/I/61l4MmL-WJL._SX679_.jpg",
      },
      {
        id: 8,
        title: "Garment Steamer",
        link: "/electric-kettles",
        icon: "https://m.media-amazon.com/images/I/61l4MmL-WJL._SX679_.jpg",
      },
      {
        id: 9,
        title: "Crockeries",
        link: "/mixer-grinders",
        icon: "https://m.media-amazon.com/images/I/61l4MmL-WJL._SX679_.jpg",
      },
      {
        id: 10,
        title: "Classy Bottles",
        link: "/oven",
        icon: "https://m.media-amazon.com/images/I/61l4MmL-WJL._SX679_.jpg",
      },
      {
        id: 11,
        title: "Plates",
        link: "/plates",
        icon: "https://m.media-amazon.com/images/I/61l4MmL-WJL._SX679_.jpg",
      },
      {
        id: 12,
        title: "Steam Iron",
        link: "/mugs",
        icon: "https://m.media-amazon.com/images/I/61l4MmL-WJL._SX679_.jpg",
      },
      {
        id: 13,
        title: "Cups",
        link: "/cups",
        icon: "https://m.media-amazon.com/images/I/61l4MmL-WJL._SX679_.jpg",
      },
      {
        id: 14,
        title: "Juicer",
        link: "/juicer",
        icon: "https://m.media-amazon.com/images/I/61l4MmL-WJL._SX679_.jpg",
      },
      {
        id: 15,
        title: "Mixer Grinders",
        link: "/mixer-grinders",
        icon: "https://m.media-amazon.com/images/I/61l4MmL-WJL._SX679_.jpg",
      },
      {
        id: 16,
        title: "Fans",
        link: "/fans",
        icon: "https://m.media-amazon.com/images/I/61l4MmL-WJL._SX679_.jpg",
      },
      {
        id: 17,
        title: "Water Purifiers",
        link: "/water-purifiers",
        icon: "https://m.media-amazon.com/images/I/61l4MmL-WJL._SX679_.jpg",
      },
      {
        id: 18,
        title: "Coolers",
        link: "/coolers",
        icon: "https://m.media-amazon.com/images/I/61l4MmL-WJL._SX679_.jpg",
      },
      {
        id: 19,
        title: "OTG's",
        link: "/otgs",
        icon: "https://m.media-amazon.com/images/I/61l4MmL-WJL._SX679_.jpg",
      },
      {
        id: 20,
        title: "AC",
        link: "/ac",
        icon: "https://m.media-amazon.com/images/I/61l4MmL-WJL._SX679_.jpg",
      },
      {
        id: 21,
        title: "Iron",
        link: "/iron",
        icon: "https://m.media-amazon.com/images/I/61l4MmL-WJL._SX679_.jpg",
      },
      {
        id: 22,
        title: "Overn",
        link: "/oven",
        icon: "https://m.media-amazon.com/images/I/61l4MmL-WJL._SX679_.jpg",
      },
      {
        id: 23,
        title: "Egg Boiler",
        link: "/egg-boiler",
        icon: "https://m.media-amazon.com/images/I/61l4MmL-WJL._SX679_.jpg",
      },
      {
        id: 24,
        title: "Inverter batteries",
        link: "/inverter-batteries",
        icon: "https://m.media-amazon.com/images/I/61l4MmL-WJL._SX679_.jpg",
      },
      {
        id: 25,
        title: "Automatic Coffee Machine",
        link: "/automatic-coffee-machines",
        icon: "https://m.media-amazon.com/images/I/61l4MmL-WJL._SX679_.jpg",
      },
      {
        id: 26,
        title: "Massager",
        link: "/massager",
        icon: "https://m.media-amazon.com/images/I/61l4MmL-WJL._SX679_.jpg",
      },
      {
        id: 26,
        title: "Refrigerators",
        link: "/refrigerators",
        icon: "https://m.media-amazon.com/images/I/61l4MmL-WJL._SX679_.jpg",
      },
      {
        id: 27,
        title: "Dish Washer",
        link: "/dish-washer",
        icon: "https://m.media-amazon.com/images/I/61l4MmL-WJL._SX679_.jpg",
      },
      {
        id: 28,
        title: "Festive Sale",
        link: "/festive_sale",
        icon: "https://m.media-amazon.com/images/I/61l4MmL-WJL._SX679_.jpg",
        type: "card",
      },
      {
        id: 29,
        title: "Biggest Discounts",
        link: "/discounts",
        icon: "https://m.media-amazon.com/images/I/61l4MmL-WJL._SX679_.jpg",
        type: "card",
      },
    ],
  },
  {
    id: 2,
    title: "Clothing",
    link: "/clothing-partners",
    products: [
      {
        id: 1,
        title: "Mens",
        link: "/men",
        icon: "https://m.media-amazon.com/images/I/61l4MmL-WJL._SX679_.jpg",
      },
      {
        id: 2,
        title: "Womens",
        link: "/women",
        icon: "https://m.media-amazon.com/images/I/61l4MmL-WJL._SX679_.jpg",
      },
      {
        id: 3,
        title: "Bridal Lehenga",
        link: "/bridal-lehenga",
        icon: "https://m.media-amazon.com/images/I/61l4MmL-WJL._SX679_.jpg",
      },
      {
        id: 4,
        title: "Mens Ethnic Wear",
        link: "/ethnic",
        icon: "https://m.media-amazon.com/images/I/61l4MmL-WJL._SX679_.jpg",
      },
      {
        id: 5,
        title: "Kids",
        link: "/oven",
        icon: "https://m.media-amazon.com/images/I/61l4MmL-WJL._SX679_.jpg",
      },
      {
        id: 6,
        title: "T-Shirts",
        link: "/tshirts",
        icon: "https://m.media-amazon.com/images/I/61l4MmL-WJL._SX679_.jpg",
      },
      {
        id: 7,
        title: "Shirts",
        link: "/Shirts",
        icon: "https://m.media-amazon.com/images/I/61l4MmL-WJL._SX679_.jpg",
      },
      {
        id: 8,
        title: "Dresses",
        link: "/dresses",
        icon: "https://m.media-amazon.com/images/I/61l4MmL-WJL._SX679_.jpg",
      },
      {
        id: 9,
        title: "Jeans & Denim",
        link: "/jeans-denim",
        icon: "https://m.media-amazon.com/images/I/61l4MmL-WJL._SX679_.jpg",
      },
      {
        id: 10,
        title: "Pants & Trousers",
        link: "/pants",
        icon: "https://m.media-amazon.com/images/I/61l4MmL-WJL._SX679_.jpg",
      },
      {
        id: 11,
        title: "Skirts",
        link: "/skirts",
        icon: "https://m.media-amazon.com/images/I/61l4MmL-WJL._SX679_.jpg",
      },
      {
        id: 12,
        title: "Shorts",
        link: "/shorts",
        icon: "https://m.media-amazon.com/images/I/61l4MmL-WJL._SX679_.jpg",
      },
      {
        id: 13,
        title: "Jackets & Coats",
        link: "/jackets-coats",
        icon: "https://m.media-amazon.com/images/I/61l4MmL-WJL._SX679_.jpg",
      },
      {
        id: 14,
        title: "Sweaters & Hoodies",
        link: "/sweaters-hoodies",
        icon: "https://m.media-amazon.com/images/I/61l4MmL-WJL._SX679_.jpg",
      },
      {
        id: 15,
        title: "Activewear & Sportswear",
        link: "/activewear-sportswear",
        icon: "https://m.media-amazon.com/images/I/61l4MmL-WJL._SX679_.jpg",
      },
      {
        id: 16,
        title: "Footwear",
        link: "/footwear",
        icon: "https://m.media-amazon.com/images/I/61l4MmL-WJL._SX679_.jpg",
      },
      {
        id: 17,
        title: "Accessories",
        link: "/accessories",
        icon: "https://m.media-amazon.com/images/I/61l4MmL-WJL._SX679_.jpg",
      },
      {
        id: 18,
        title: "Jewelry & Watches",
        link: "/jewelry-watches",
        icon: "https://m.media-amazon.com/images/I/61l4MmL-WJL._SX679_.jpg",
      },
      {
        id: 19,
        title: "OTG's",
        link: "/otgs",
        icon: "https://m.media-amazon.com/images/I/61l4MmL-WJL._SX679_.jpg",
      },
      {
        id: 20,
        title: "Lingerie",
        link: "/lingerie",
        icon: "https://m.media-amazon.com/images/I/61l4MmL-WJL._SX679_.jpg",
      },
      {
        id: 21,
        title: "FLAT 50% Off on Wedding Season",
        link: "/festive_sale",
        icon: "https://m.media-amazon.com/images/I/61l4MmL-WJL._SX679_.jpg",
        type: "card",
      },
      {
        id: 22,
        title: "Biggest Discounts",
        link: "/discounts",
        icon: "https://m.media-amazon.com/images/I/61l4MmL-WJL._SX679_.jpg",
        type: "card",
      },
    ],
  },
  {
    id: 3,
    title: "Services",
    link: "/services",
    products: [
      {
        id: 1,
        title: "House Cleaning & Maid Services",
        link: "/maid",
        icon: "https://m.media-amazon.com/images/I/61l4MmL-WJL._SX679_.jpg",
      },
      {
        id: 2,
        title: "Plumbing Services",
        link: "/plumbing-services",
        icon: "https://m.media-amazon.com/images/I/61l4MmL-WJL._SX679_.jpg",
      },
      {
        id: 3,
        title: "Electrical Services",
        link: "/electrical-services",
        icon: "https://m.media-amazon.com/images/I/61l4MmL-WJL._SX679_.jpg",
      },
      {
        id: 4,
        title: "Security Guards",
        link: "/home-security-surveillance",
        icon: "https://m.media-amazon.com/images/I/61l4MmL-WJL._SX679_.jpg",
      },
      {
        id: 5,
        title: "Carpenter",
        link: "/carpenter",
        icon: "https://m.media-amazon.com/images/I/61l4MmL-WJL._SX679_.jpg",
      },
      {
        id: 6,
        title: "Dentist",
        link: "/dentist",
        icon: "https://m.media-amazon.com/images/I/61l4MmL-WJL._SX679_.jpg",
      },
      {
        id: 7,
        title: "Nutritionist",
        link: "/nutritionist",
        icon: "https://m.media-amazon.com/images/I/61l4MmL-WJL._SX679_.jpg",
      },
      {
        id: 8,
        title: "Physiotherapist",
        link: "/physiotherapist",
        icon: "https://m.media-amazon.com/images/I/61l4MmL-WJL._SX679_.jpg",
      },
      {
        id: 9,
        title: "Special Care",
        link: "/special-care",
        icon: "https://m.media-amazon.com/images/I/61l4MmL-WJL._SX679_.jpg",
      },
      {
        id: 10,
        title: "Ophthalmologist",
        link: "/ophthalmologist",
        icon: "https://m.media-amazon.com/images/I/61l4MmL-WJL._SX679_.jpg",
      },
      {
        id: 11,
        title: "Pharmacy",
        link: "/pharmacy",
        icon: "https://m.media-amazon.com/images/I/61l4MmL-WJL._SX679_.jpg",
      },
      {
        id: 12,
        title: "Ambulance Service",
        link: "/ambulance",
        icon: "https://m.media-amazon.com/images/I/61l4MmL-WJL._SX679_.jpg",
      },
      {
        id: 13,
        title: "Tutors",
        link: "/tutors",
        icon: "https://m.media-amazon.com/images/I/61l4MmL-WJL._SX679_.jpg",
      },
      {
        id: 14,
        title: "Chefs",
        link: "/chefs",
        icon: "https://m.media-amazon.com/images/I/61l4MmL-WJL._SX679_.jpg",
      },
      {
        id: 15,
        title: "Activewear & Sportswear",
        link: "/activewear-sportswear",
        icon: "https://m.media-amazon.com/images/I/61l4MmL-WJL._SX679_.jpg",
      },
      {
        id: 16,
        title: "Pest Control",
        link: "/pest-control",
        icon: "https://m.media-amazon.com/images/I/61l4MmL-WJL._SX679_.jpg",
      },
      {
        id: 17,
        title: "Water Purifiers Technician",
        link: "/water-purifiers",
        icon: "https://m.media-amazon.com/images/I/61l4MmL-WJL._SX679_.jpg",
      },
      {
        id: 18,
        title: "Painters",
        link: "/painters",
        icon: "https://m.media-amazon.com/images/I/61l4MmL-WJL._SX679_.jpg",
      },
      {
        id: 19,
        title: "Women's Saloon and Spa",
        link: "/women-salon",
        icon: "https://m.media-amazon.com/images/I/61l4MmL-WJL._SX679_.jpg",
      },
      {
        id: 20,
        title: "Washing Machine Repair",
        link: "/washing-machine-repair",
        icon: "https://m.media-amazon.com/images/I/61l4MmL-WJL._SX679_.jpg",
      },
      {
        id: 21,
        title: "FLAT 50% Off on AC Repair",
        link: "/ac-repair",
        icon: "https://m.media-amazon.com/images/I/61l4MmL-WJL._SX679_.jpg",
        type: "card",
      },
      {
        id: 22,
        title: "Biggest Discounts by our partners",
        link: "/discounts",
        icon: "https://m.media-amazon.com/images/I/61l4MmL-WJL._SX679_.jpg",
        type: "card",
      },
    ],
  },
  {
    id: 4,
    title: "Restaurant",
    link: "/restaurant-partners",
    products: [
      {
        type: "search",
        offer: "Hungry? Search dish and Find all nearby restaurants",
        placeholder: "Search dishes, restaurant, cloud kitchens...",
        footer: "Curated list of nearby orange verified restaurants.",
        photo:
          "https://images.pexels.com/photos/1581384/pexels-photo-1581384.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      },
    ],
  },
  {
    id: 5,
    title: "Automobile",
    link: "/automobile-partners",
    products: [
      {
        type: "search",
        offer: "Search automobile and Find all nearby dealers.",
        placeholder: "Search Cars, Bike, Truck, Tractor...",
        footer: "Curated list of nearby orange verified automobile dealers.",
        photo:
          "https://cdn.pixabay.com/photo/2023/03/15/03/46/jeep-7853620_1280.jpg",
      },
    ],
  },
  {
    id: 6,
    title: "Real Estate",
    link: "/real-estate",
    products: [
      {
        type: "search",
        offer: "Find all nearby brokers.",
        placeholder: "Search flats, penthouse, land, commercial plots...",
        footer:
          "Curated list of nearby orange verified property dealers, owners.",
        photo:
          "https://images.pexels.com/photos/302769/pexels-photo-302769.jpeg?auto=compress&cs=tinysrgb&w=800",
      },
    ],
  },
];

export const PARTNERS = {
  appliances: ["ABC Electronics", "Vijay Sales", "Croma", "RVCA", "ROXY"],
  clothing: ["ABC Electronics", "Vijay Sales", "Croma", "RVCA", "ROXY"],
  wholesale: ["ABC Electronics", "Vijay Sales", "Croma", "RVCA", "ROXY"],
  restaurant: ["ABC Electronics", "Vijay Sales", "Croma", "RVCA", "ROXY"],
  automobile: ["ABC Electronics", "Vijay Sales", "Croma", "RVCA", "ROXY"],
};
export const PREMIUM_MEMBERS = [
  {
    id: 1,
    company_name: "Petty Pets",
    image: petshop,
    tagLine: "Get a new member today",
  },
  {
    id: 2,
    company_name: "Decorium",
    image: home_decor,
    tagLine: "Get Premium Home Decors",
  },
  {
    id: 3,
    company_name: "Car-ify",
    image: automobile,
    tagLine: "Deals in premium cars, car parts and tyres. ",
  },
];

export const HEADER_TAGLINE = "Your Digital Showroom.";

export const BANNER_SLIDES = [
  {
    // video: clothing_video,
    image: clothing_photo,
    title: "Buy from top designers",
  },
  {
    // video: pet_dog_video,
    image: pet_banner,
    title: "Looking for a paw-sitivity",
  },
  {
    // video: car_video,
    image: car_store,
    title: "Biggest dealer awaits just a click away",
  },
];

export const CLOTHING = [
  {
    id: 1,
    product_name: "Bridal Collection",
    image:
      "https://i.pinimg.com/474x/50/02/25/500225315b1aacfab1cdc106924aedff.jpg",
    price: "4,999",
  },
  {
    id: 2,
    product_name: "Women's Collection",
    price: "4,999",
    image:
      "https://static.vecteezy.com/system/resources/previews/028/622/811/large_2x/beautiful-asian-fashion-model-ai-generated-free-photo.jpg",
  },
  {
    id: 3,
    product_name: "Men's Collection",
    image:
      "https://media.istockphoto.com/id/1201026026/photo/stylish-man-wearing-sunglasses-and-white-shirt-city-life.jpg?s=612x612&w=0&k=20&c=Lw3M3Eq3Cwwc7OqR4z3xVqEQvRBrGvQXbUDY8jB7eOE=",
  },
  {
    id: 4,
    product_name: "Ethnic Wear",
    image:
      "https://www.kalkifashion.com/blogs/wp-content/uploads/2023/11/Evolution_of_Indian_Mens_Ethnic_Wear_From_Tradition_to_Trendy.jpg",
  },
  {
    id: 1,
    product_name: "Bridal Collection",
    image:
      "https://i.pinimg.com/474x/50/02/25/500225315b1aacfab1cdc106924aedff.jpg",
  },
  {
    id: 2,
    product_name: "Women's Collection",
    image:
      "https://static.vecteezy.com/system/resources/previews/028/622/811/large_2x/beautiful-asian-fashion-model-ai-generated-free-photo.jpg",
  },
  {
    id: 3,
    product_name: "Men's Collection",
    image:
      "https://media.istockphoto.com/id/1201026026/photo/stylish-man-wearing-sunglasses-and-white-shirt-city-life.jpg?s=612x612&w=0&k=20&c=Lw3M3Eq3Cwwc7OqR4z3xVqEQvRBrGvQXbUDY8jB7eOE=",
  },
  {
    id: 4,
    product_name: "Ethnic Wear",
    image:
      "https://www.kalkifashion.com/blogs/wp-content/uploads/2023/11/Evolution_of_Indian_Mens_Ethnic_Wear_From_Tradition_to_Trendy.jpg",
  },
];

export const KITCHEN_APPLIANCES = [
  {
    id: 1,
    price: "4,999",
    quantity: "1500",
    product_name: "Mixer",
    price: "4,999",
    quantity: "1500",
    type: "ecommerce",
    image:
      "https://plus.unsplash.com/premium_photo-1683140593992-4a963b0b9f4b?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8a2l0Y2hlbiUyMGFwcGxpYW5jZXN8ZW58MHx8MHx8fDA%3D",
    product_details: {
      assets: [
        "https://plus.unsplash.com/premium_photo-1683140593992-4a963b0b9f4b?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8a2l0Y2hlbiUyMGFwcGxpYW5jZXN8ZW58MHx8MHx8fDA%3D",
        "https://plus.unsplash.com/premium_photo-1683140593992-4a963b0b9f4b?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8a2l0Y2hlbiUyMGFwcGxpYW5jZXN8ZW58MHx8MHx8fDA%3D",
        "https://plus.unsplash.com/premium_photo-1683140593992-4a963b0b9f4b?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8a2l0Y2hlbiUyMGFwcGxpYW5jZXN8ZW58MHx8MHx8fDA%3D",
        "https://plus.unsplash.com/premium_photo-1683140593992-4a963b0b9f4b?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8a2l0Y2hlbiUyMGFwcGxpYW5jZXN8ZW58MHx8MHx8fDA%3D",
        "https://plus.unsplash.com/premium_photo-1683140593992-4a963b0b9f4b?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8a2l0Y2hlbiUyMGFwcGxpYW5jZXN8ZW58MHx8MHx8fDA%3D",
      ],
      main_image:
        "https://plus.unsplash.com/premium_photo-1683140593992-4a963b0b9f4b?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8a2l0Y2hlbiUyMGFwcGxpYW5jZXN8ZW58MHx8MHx8fDA%3D",
      product_description:
        "BOSCH MG2213C3BI TrueMixx Serie|2 500 W Mixer Grinder (3 Jars, Blue)",
    },
    more_products: [
      {
        image:
          "https://plus.unsplash.com/premium_photo-1683140593992-4a963b0b9f4b?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8a2l0Y2hlbiUyMGFwcGxpYW5jZXN8ZW58MHx8MHx8fDA%3D",
        name: "Mixer",
        price: "23,00,000",
      },
      {
        image:
          "https://plus.unsplash.com/premium_photo-1683140593992-4a963b0b9f4b?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8a2l0Y2hlbiUyMGFwcGxpYW5jZXN8ZW58MHx8MHx8fDA%3D",
        name: "Mixer",
        price: "23,00,000",
      },
      {
        image:
          "https://plus.unsplash.com/premium_photo-1683140593992-4a963b0b9f4b?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8a2l0Y2hlbiUyMGFwcGxpYW5jZXN8ZW58MHx8MHx8fDA%3D",
        name: "Mixer",
        price: "23,00,000",
      },
      {
        image:
          "https://plus.unsplash.com/premium_photo-1683140593992-4a963b0b9f4b?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8a2l0Y2hlbiUyMGFwcGxpYW5jZXN8ZW58MHx8MHx8fDA%3D",
        name: "Mixer",
        price: "23,00,000",
      },
    ],
  },
  {
    id: 2,
    price: "4,999",
    quantity: "1500",
    product_name: "Air Fryer",
    image:
      "https://plus.unsplash.com/premium_photo-1672192166833-c8ae84e5e127?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8QWlyJTIwZnJ5ZXJ8ZW58MHx8MHx8fDA%3D",
  },
  {
    id: 3,
    price: "4,999",
    quantity: "1500",
    product_name: "Coffee Mugs",
    image:
      "https://images.unsplash.com/photo-1513521773210-0cc22dfee8db?q=80&w=3087&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    id: 4,
    price: "4,999",
    quantity: "1500",
    product_name: "Electric Kettle",
    image:
      "https://images.unsplash.com/photo-1613585815189-eafe895d233b?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTR8fGVsZWN0cmljJTIwa2V0dGxlfGVufDB8fDB8fHww",
  },
  {
    id: 1,
    price: "4,999",
    quantity: "1500",
    product_name: "Mixer",
    image:
      "https://plus.unsplash.com/premium_photo-1683140593992-4a963b0b9f4b?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8a2l0Y2hlbiUyMGFwcGxpYW5jZXN8ZW58MHx8MHx8fDA%3D",
  },
  {
    id: 2,
    price: "4,999",
    quantity: "1500",
    product_name: "Air Fryer",
    image:
      "https://plus.unsplash.com/premium_photo-1672192166833-c8ae84e5e127?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8QWlyJTIwZnJ5ZXJ8ZW58MHx8MHx8fDA%3D",
  },
  {
    id: 3,
    price: "4,999",
    quantity: "1500",
    product_name: "Coffee Mugs",
    image:
      "https://images.unsplash.com/photo-1513521773210-0cc22dfee8db?q=80&w=3087&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    id: 4,
    price: "4,999",
    quantity: "1500",
    product_name: "Electric Kettle",
    image:
      "https://images.unsplash.com/photo-1613585815189-eafe895d233b?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTR8fGVsZWN0cmljJTIwa2V0dGxlfGVufDB8fDB8fHww",
  },
  {
    id: 1,
    price: "4,999",
    quantity: "1500",
    product_name: "Mixer",
    image:
      "https://plus.unsplash.com/premium_photo-1683140593992-4a963b0b9f4b?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8a2l0Y2hlbiUyMGFwcGxpYW5jZXN8ZW58MHx8MHx8fDA%3D",
  },
  {
    id: 2,
    price: "4,999",
    quantity: "1500",
    product_name: "Air Fryer",
    image:
      "https://plus.unsplash.com/premium_photo-1672192166833-c8ae84e5e127?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8QWlyJTIwZnJ5ZXJ8ZW58MHx8MHx8fDA%3D",
  },
  {
    id: 3,
    price: "4,999",
    quantity: "1500",
    product_name: "Coffee Mugs",
    image:
      "https://images.unsplash.com/photo-1513521773210-0cc22dfee8db?q=80&w=3087&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    id: 4,
    price: "4,999",
    quantity: "1500",
    product_name: "Electric Kettle",
    image:
      "https://images.unsplash.com/photo-1613585815189-eafe895d233b?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTR8fGVsZWN0cmljJTIwa2V0dGxlfGVufDB8fDB8fHww",
  },
  {
    id: 1,
    price: "4,999",
    quantity: "1500",
    product_name: "Mixer",
    image:
      "https://plus.unsplash.com/premium_photo-1683140593992-4a963b0b9f4b?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8a2l0Y2hlbiUyMGFwcGxpYW5jZXN8ZW58MHx8MHx8fDA%3D",
  },
  {
    id: 2,
    price: "4,999",
    quantity: "1500",
    product_name: "Air Fryer",
    image:
      "https://plus.unsplash.com/premium_photo-1672192166833-c8ae84e5e127?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8QWlyJTIwZnJ5ZXJ8ZW58MHx8MHx8fDA%3D",
  },
  {
    id: 3,
    price: "4,999",
    quantity: "1500",
    product_name: "Coffee Mugs",
    image:
      "https://images.unsplash.com/photo-1513521773210-0cc22dfee8db?q=80&w=3087&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    id: 4,
    price: "4,999",
    quantity: "1500",
    product_name: "Electric Kettle",
    image:
      "https://images.unsplash.com/photo-1613585815189-eafe895d233b?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTR8fGVsZWN0cmljJTIwa2V0dGxlfGVufDB8fDB8fHww",
  },
  {
    id: 1,
    price: "4,999",
    quantity: "1500",
    product_name: "Mixer",
    image:
      "https://plus.unsplash.com/premium_photo-1683140593992-4a963b0b9f4b?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8a2l0Y2hlbiUyMGFwcGxpYW5jZXN8ZW58MHx8MHx8fDA%3D",
  },
  {
    id: 2,
    price: "4,999",
    quantity: "1500",
    product_name: "Air Fryer",
    image:
      "https://plus.unsplash.com/premium_photo-1672192166833-c8ae84e5e127?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8QWlyJTIwZnJ5ZXJ8ZW58MHx8MHx8fDA%3D",
  },
  {
    id: 3,
    price: "4,999",
    quantity: "1500",
    product_name: "Coffee Mugs",
    image:
      "https://images.unsplash.com/photo-1513521773210-0cc22dfee8db?q=80&w=3087&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    id: 4,
    price: "4,999",
    quantity: "1500",
    product_name: "Electric Kettle",
    image:
      "https://images.unsplash.com/photo-1613585815189-eafe895d233b?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTR8fGVsZWN0cmljJTIwa2V0dGxlfGVufDB8fDB8fHww",
  },
  {
    id: 1,
    price: "4,999",
    quantity: "1500",
    product_name: "Mixer",
    image:
      "https://plus.unsplash.com/premium_photo-1683140593992-4a963b0b9f4b?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8a2l0Y2hlbiUyMGFwcGxpYW5jZXN8ZW58MHx8MHx8fDA%3D",
  },
  {
    id: 2,
    price: "4,999",
    quantity: "1500",
    product_name: "Air Fryer",
    image:
      "https://plus.unsplash.com/premium_photo-1672192166833-c8ae84e5e127?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8QWlyJTIwZnJ5ZXJ8ZW58MHx8MHx8fDA%3D",
  },
  {
    id: 3,
    price: "4,999",
    quantity: "1500",
    product_name: "Coffee Mugs",
    image:
      "https://images.unsplash.com/photo-1513521773210-0cc22dfee8db?q=80&w=3087&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    id: 4,
    price: "4,999",
    quantity: "1500",
    product_name: "Electric Kettle",
    image:
      "https://images.unsplash.com/photo-1613585815189-eafe895d233b?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTR8fGVsZWN0cmljJTIwa2V0dGxlfGVufDB8fDB8fHww",
  },
  {
    id: 1,
    price: "4,999",
    quantity: "1500",
    product_name: "Mixer",
    image:
      "https://plus.unsplash.com/premium_photo-1683140593992-4a963b0b9f4b?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8a2l0Y2hlbiUyMGFwcGxpYW5jZXN8ZW58MHx8MHx8fDA%3D",
  },
  {
    id: 2,
    price: "4,999",
    quantity: "1500",
    product_name: "Air Fryer",
    image:
      "https://plus.unsplash.com/premium_photo-1672192166833-c8ae84e5e127?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8QWlyJTIwZnJ5ZXJ8ZW58MHx8MHx8fDA%3D",
  },
  {
    id: 3,
    price: "4,999",
    quantity: "1500",
    product_name: "Coffee Mugs",
    image:
      "https://images.unsplash.com/photo-1513521773210-0cc22dfee8db?q=80&w=3087&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    id: 4,
    price: "4,999",
    quantity: "1500",
    product_name: "Electric Kettle",
    image:
      "https://images.unsplash.com/photo-1613585815189-eafe895d233b?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTR8fGVsZWN0cmljJTIwa2V0dGxlfGVufDB8fDB8fHww",
  },
  {
    id: 1,
    price: "4,999",
    quantity: "1500",
    product_name: "Mixer",
    image:
      "https://plus.unsplash.com/premium_photo-1683140593992-4a963b0b9f4b?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8a2l0Y2hlbiUyMGFwcGxpYW5jZXN8ZW58MHx8MHx8fDA%3D",
  },
  {
    id: 2,
    price: "4,999",
    quantity: "1500",
    product_name: "Air Fryer",
    image:
      "https://plus.unsplash.com/premium_photo-1672192166833-c8ae84e5e127?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8QWlyJTIwZnJ5ZXJ8ZW58MHx8MHx8fDA%3D",
  },
  {
    id: 3,
    price: "4,999",
    quantity: "1500",
    product_name: "Coffee Mugs",
    image:
      "https://images.unsplash.com/photo-1513521773210-0cc22dfee8db?q=80&w=3087&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    id: 4,
    price: "4,999",
    quantity: "1500",
    product_name: "Electric Kettle",
    image:
      "https://images.unsplash.com/photo-1613585815189-eafe895d233b?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTR8fGVsZWN0cmljJTIwa2V0dGxlfGVufDB8fDB8fHww",
  },
  {
    id: 1,
    price: "4,999",
    quantity: "1500",
    product_name: "Mixer",
    image:
      "https://plus.unsplash.com/premium_photo-1683140593992-4a963b0b9f4b?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8a2l0Y2hlbiUyMGFwcGxpYW5jZXN8ZW58MHx8MHx8fDA%3D",
  },
  {
    id: 2,
    price: "4,999",
    quantity: "1500",
    product_name: "Air Fryer",
    image:
      "https://plus.unsplash.com/premium_photo-1672192166833-c8ae84e5e127?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8QWlyJTIwZnJ5ZXJ8ZW58MHx8MHx8fDA%3D",
  },
  {
    id: 3,
    price: "4,999",
    quantity: "1500",
    product_name: "Coffee Mugs",
    image:
      "https://images.unsplash.com/photo-1513521773210-0cc22dfee8db?q=80&w=3087&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    id: 4,
    price: "4,999",
    quantity: "1500",
    product_name: "Electric Kettle",
    image:
      "https://images.unsplash.com/photo-1613585815189-eafe895d233b?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTR8fGVsZWN0cmljJTIwa2V0dGxlfGVufDB8fDB8fHww",
  },
];

export const PET_SHOP = [
  {
    id: 1,
    product_name: "Dogs",
    image:
      "https://images.unsplash.com/photo-1517849845537-4d257902454a?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8cGV0fGVufDB8fDB8fHww",
  },
  {
    id: 2,
    product_name: "Cats",
    image:
      "https://images.unsplash.com/photo-1548366086-7f1b76106622?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTV8fGNhdHN8ZW58MHx8MHx8fDA%3D",
  },
  {
    id: 3,
    product_name: "Pet Food",
    image:
      "https://m.media-amazon.com/images/I/61c-pIm5oRL._AC_UF894,1000_QL80_.jpg",
  },
  {
    id: 4,
    product_name: "Pet Accessories",
    image:
      "https://images.unsplash.com/photo-1577611612331-845d0e3d77af?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTF8fFBldCUyMGFjY2Vzc29yaWVzfGVufDB8fDB8fHww",
  },
  {
    id: 1,
    product_name: "Dogs",
    image:
      "https://images.unsplash.com/photo-1517849845537-4d257902454a?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8cGV0fGVufDB8fDB8fHww",
  },
  {
    id: 2,
    product_name: "Cats",
    image:
      "https://images.unsplash.com/photo-1548366086-7f1b76106622?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTV8fGNhdHN8ZW58MHx8MHx8fDA%3D",
  },
  {
    id: 3,
    product_name: "Pet Food",
    image:
      "https://m.media-amazon.com/images/I/61c-pIm5oRL._AC_UF894,1000_QL80_.jpg",
  },
  {
    id: 4,
    product_name: "Pet Accessories",
    image:
      "https://images.unsplash.com/photo-1577611612331-845d0e3d77af?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTF8fFBldCUyMGFjY2Vzc29yaWVzfGVufDB8fDB8fHww",
  },
];

export const AUTOMOBILE = [
  {
    id: 1,
    product_name: "Mercedes GLA 300",
    product_details: {
      assets: [
        "https://imgd.aeplcdn.com/1920x1080/n/cw/ec/169159/gla-facelift-exterior-right-front-three-quarter-2.jpeg?isig=0&q=80&q=80",
        "https://imgd.aeplcdn.com/1920x1080/n/cw/ec/169159/gla-facelift-exterior-right-front-three-quarter-2.jpeg?isig=0&q=80&q=80",
        "https://imgd.aeplcdn.com/1920x1080/n/cw/ec/169159/gla-facelift-exterior-right-front-three-quarter-2.jpeg?isig=0&q=80&q=80",
        "https://imgd.aeplcdn.com/1920x1080/n/cw/ec/169159/gla-facelift-exterior-right-front-three-quarter-2.jpeg?isig=0&q=80&q=80",
        "https://imgd.aeplcdn.com/1920x1080/n/cw/ec/169159/gla-facelift-exterior-right-front-three-quarter-2.jpeg?isig=0&q=80&q=80",
      ],
      main_image:
        "https://imgd.aeplcdn.com/1920x1080/n/cw/ec/169159/gla-facelift-exterior-right-front-three-quarter-2.jpeg?isig=0&q=80&q=80",
      product_description:
        "Mercedes-Benz GLA price starts at ₹ 51.75 Lakh and top model price goes upto ₹ 58.15 Lakh. GLA is offered in 3 variants - the base model of GLA is 200 and the top model Mercedes-Benz GLA 220d 4MATIC AMG Line.",
    },
    description: "ctvt 1.6ltr, ABS, Cruise Control",
    image:
      "https://imgd.aeplcdn.com/1920x1080/n/cw/ec/169159/gla-facelift-exterior-right-front-three-quarter-2.jpeg?isig=0&q=80&q=80",
    more_products: [
      {
        image:
          "https://imgd.aeplcdn.com/1920x1080/n/cw/ec/169159/gla-facelift-exterior-right-front-three-quarter-2.jpeg?isig=0&q=80&q=80",
        name: "Mercedes GLC 300",
        price: "23,00,000",
      },
      {
        image:
          "https://imgd.aeplcdn.com/1920x1080/n/cw/ec/169159/gla-facelift-exterior-right-front-three-quarter-2.jpeg?isig=0&q=80&q=80",
        name: "Mercedes GLC 300",
        price: "23,00,000",
      },
      {
        image:
          "https://imgd.aeplcdn.com/1920x1080/n/cw/ec/169159/gla-facelift-exterior-right-front-three-quarter-2.jpeg?isig=0&q=80&q=80",
        name: "Mercedes GLC 300",
        price: "23,00,000",
      },
      {
        image:
          "https://imgd.aeplcdn.com/1920x1080/n/cw/ec/169159/gla-facelift-exterior-right-front-three-quarter-2.jpeg?isig=0&q=80&q=80",
        name: "Mercedes GLC 300",
        price: "23,00,000",
      },
    ],
  },
  {
    id: 2,
    product_name: "BMW 5 series",
    description: "ctvt 1.6ltr, ABS, Cruise Control",
    image:
      "https://stimg.cardekho.com/images/carexteriorimages/930x620/BMW/5-Series-2024/10182/1685002609273/front-left-side-47.jpg",
  },
  {
    id: 3,
    product_name: "Thar Roxx",
    description: "ctvt 1.6ltr, ABS, Cruise Control",
    image:
      "https://www.team-bhp.com/forum/attachments/official-new-car-reviews/2642758d1723832110-mahindra-thar-roxx-review-2024_mahindra_tharroxx_exterior_01.jpg",
  },
  {
    id: 4,
    product_name: "XUV 700",
    description: "ctvt 1.6ltr, ABS, Cruise Control",
    image:
      "https://imgd-ct.aeplcdn.com/664x415/n/cw/ec/42355/xuv700-exterior-front-view-2.jpeg?isig=0&q=80",
  },
  {
    id: 1,
    product_name: "Mercedes GLA 300",
    description: "ctvt 1.6ltr, ABS, Cruise Control",
    image:
      "https://imgd.aeplcdn.com/1920x1080/n/cw/ec/169159/gla-facelift-exterior-right-front-three-quarter-2.jpeg?isig=0&q=80&q=80",
  },
  {
    id: 2,
    product_name: "BMW 5 series",
    description: "ctvt 1.6ltr, ABS, Cruise Control",
    image:
      "https://stimg.cardekho.com/images/carexteriorimages/930x620/BMW/5-Series-2024/10182/1685002609273/front-left-side-47.jpg",
  },
  {
    id: 3,
    product_name: "Thar Roxx",
    description: "ctvt 1.6ltr, ABS, Cruise Control",
    image:
      "https://www.team-bhp.com/forum/attachments/official-new-car-reviews/2642758d1723832110-mahindra-thar-roxx-review-2024_mahindra_tharroxx_exterior_01.jpg",
  },
  {
    id: 4,
    product_name: "XUV 700",
    description: "ctvt 1.6ltr, ABS, Cruise Control",
    image:
      "https://imgd-ct.aeplcdn.com/664x415/n/cw/ec/42355/xuv700-exterior-front-view-2.jpeg?isig=0&q=80",
  },
  {
    id: 1,
    product_name: "Mercedes GLA 300",
    description: "ctvt 1.6ltr, ABS, Cruise Control",
    image:
      "https://imgd.aeplcdn.com/1920x1080/n/cw/ec/169159/gla-facelift-exterior-right-front-three-quarter-2.jpeg?isig=0&q=80&q=80",
  },
  {
    id: 2,
    product_name: "BMW 5 series",
    description: "ctvt 1.6ltr, ABS, Cruise Control",
    image:
      "https://stimg.cardekho.com/images/carexteriorimages/930x620/BMW/5-Series-2024/10182/1685002609273/front-left-side-47.jpg",
  },
  {
    id: 3,
    product_name: "Thar Roxx",
    description: "ctvt 1.6ltr, ABS, Cruise Control",
    image:
      "https://www.team-bhp.com/forum/attachments/official-new-car-reviews/2642758d1723832110-mahindra-thar-roxx-review-2024_mahindra_tharroxx_exterior_01.jpg",
  },
  {
    id: 4,
    product_name: "XUV 700",
    description: "ctvt 1.6ltr, ABS, Cruise Control",
    image:
      "https://imgd-ct.aeplcdn.com/664x415/n/cw/ec/42355/xuv700-exterior-front-view-2.jpeg?isig=0&q=80",
  },
  {
    id: 1,
    product_name: "Mercedes GLA 300",
    description: "ctvt 1.6ltr, ABS, Cruise Control",
    image:
      "https://imgd.aeplcdn.com/1920x1080/n/cw/ec/169159/gla-facelift-exterior-right-front-three-quarter-2.jpeg?isig=0&q=80&q=80",
  },
  {
    id: 2,
    product_name: "BMW 5 series",
    description: "ctvt 1.6ltr, ABS, Cruise Control",
    image:
      "https://stimg.cardekho.com/images/carexteriorimages/930x620/BMW/5-Series-2024/10182/1685002609273/front-left-side-47.jpg",
  },
  {
    id: 3,
    product_name: "Thar Roxx",
    description: "ctvt 1.6ltr, ABS, Cruise Control",
    image:
      "https://www.team-bhp.com/forum/attachments/official-new-car-reviews/2642758d1723832110-mahindra-thar-roxx-review-2024_mahindra_tharroxx_exterior_01.jpg",
  },
  {
    id: 4,
    product_name: "XUV 700",
    description: "ctvt 1.6ltr, ABS, Cruise Control",
    image:
      "https://imgd-ct.aeplcdn.com/664x415/n/cw/ec/42355/xuv700-exterior-front-view-2.jpeg?isig=0&q=80",
  },
  {
    id: 1,
    product_name: "Mercedes GLA 300",
    description: "ctvt 1.6ltr, ABS, Cruise Control",
    image:
      "https://imgd.aeplcdn.com/1920x1080/n/cw/ec/169159/gla-facelift-exterior-right-front-three-quarter-2.jpeg?isig=0&q=80&q=80",
  },
  {
    id: 2,
    product_name: "BMW 5 series",
    description: "ctvt 1.6ltr, ABS, Cruise Control",
    image:
      "https://stimg.cardekho.com/images/carexteriorimages/930x620/BMW/5-Series-2024/10182/1685002609273/front-left-side-47.jpg",
  },
  {
    id: 3,
    product_name: "Thar Roxx",
    description: "ctvt 1.6ltr, ABS, Cruise Control",
    image:
      "https://www.team-bhp.com/forum/attachments/official-new-car-reviews/2642758d1723832110-mahindra-thar-roxx-review-2024_mahindra_tharroxx_exterior_01.jpg",
  },
  {
    id: 4,
    product_name: "XUV 700",
    description: "ctvt 1.6ltr, ABS, Cruise Control",
    image:
      "https://imgd-ct.aeplcdn.com/664x415/n/cw/ec/42355/xuv700-exterior-front-view-2.jpeg?isig=0&q=80",
  },
  {
    id: 3,
    product_name: "Thar Roxx",
    description: "ctvt 1.6ltr, ABS, Cruise Control",
    image:
      "https://www.team-bhp.com/forum/attachments/official-new-car-reviews/2642758d1723832110-mahindra-thar-roxx-review-2024_mahindra_tharroxx_exterior_01.jpg",
  },
  {
    id: 4,
    product_name: "XUV 700",
    description: "ctvt 1.6ltr, ABS, Cruise Control",
    image:
      "https://imgd-ct.aeplcdn.com/664x415/n/cw/ec/42355/xuv700-exterior-front-view-2.jpeg?isig=0&q=80",
  },
  {
    id: 4,
    product_name: "XUV 700",
    description: "ctvt 1.6ltr, ABS, Cruise Control",
    image:
      "https://imgd-ct.aeplcdn.com/664x415/n/cw/ec/42355/xuv700-exterior-front-view-2.jpeg?isig=0&q=80",
  },
  {
    id: 4,
    product_name: "XUV 700",
    description: "ctvt 1.6ltr, ABS, Cruise Control",
    image:
      "https://imgd-ct.aeplcdn.com/664x415/n/cw/ec/42355/xuv700-exterior-front-view-2.jpeg?isig=0&q=80",
  },
];

export const DISCOUNTED_CLOTHING = [
  {
    id: 1,
    product_name: "Bridal Collection",
    image:
      "https://static.vecteezy.com/system/resources/previews/028/622/811/large_2x/beautiful-asian-fashion-model-ai-generated-free-photo.jpg",
  },
  {
    id: 2,
    product_name: "Women's Collection",
    image:
      "https://encrypted-tbn3.gstatic.com/shopping?q=tbn:ANd9GcTNExhuYG1Ay0EXVv5aCibXgVhjFxKA5XsR_Cp71F4Kpabng2_ghcra12DZDsnhjCfs1nXCyfd7EhLh2wpVNx8S1ocEjGsDNBhRcyqtWowl",
    offer: "Flat 99% Off",
  },
  {
    id: 3,
    product_name: "Men's Collection",
    image:
      "https://media.istockphoto.com/id/1201026026/photo/stylish-man-wearing-sunglasses-and-white-shirt-city-life.jpg?s=612x612&w=0&k=20&c=Lw3M3Eq3Cwwc7OqR4z3xVqEQvRBrGvQXbUDY8jB7eOE=",
    offer: "Flat 90% Off",
  },
  {
    id: 4,
    product_name: "Men's Collection",
    image:
      "https://media.istockphoto.com/id/1201026026/photo/stylish-man-wearing-sunglasses-and-white-shirt-city-life.jpg?s=612x612&w=0&k=20&c=Lw3M3Eq3Cwwc7OqR4z3xVqEQvRBrGvQXbUDY8jB7eOE=",
    offer: "Flat 5% Off",
  },
  {
    id: 5,
    product_name: "Men's Collection",
    image:
      "https://media.istockphoto.com/id/1201026026/photo/stylish-man-wearing-sunglasses-and-white-shirt-city-life.jpg?s=612x612&w=0&k=20&c=Lw3M3Eq3Cwwc7OqR4z3xVqEQvRBrGvQXbUDY8jB7eOE=",
    offer: "Flat 85% Off",
  },
];

export const DISCOUNTED_KITCHEN_APPLIANCES = [
  {
    id: 1,
    product_name: "Coffee Machine",
    image:
      "https://images.unsplash.com/photo-1542067953-528621275a71?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fGNvZmZlZSUyMG1hY2hpbmV8ZW58MHx8MHx8fDA%3D",
  },
  {
    id: 2,
    product_name: "Mixer",
    offer: "Flat 40% Off",
    image:
      "https://plus.unsplash.com/premium_photo-1683140593992-4a963b0b9f4b?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8a2l0Y2hlbiUyMGFwcGxpYW5jZXN8ZW58MHx8MHx8fDA%3D",
  },
  {
    id: 3,
    product_name: "Air Fryer",
    offer: "Flat 60% Off",
    image:
      "https://plus.unsplash.com/premium_photo-1672192166833-c8ae84e5e127?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8QWlyJTIwZnJ5ZXJ8ZW58MHx8MHx8fDA%3D",
  },
  {
    id: 4,
    product_name: "Coffee Mugs",
    offer: "Flat 80% Off",
    image:
      "https://images.unsplash.com/photo-1513521773210-0cc22dfee8db?q=80&w=3087&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    id: 5,
    product_name: "Electric Kettle",
    offer: "Flat 60% Off",
    image:
      "https://images.unsplash.com/photo-1613585815189-eafe895d233b?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTR8fGVsZWN0cmljJTIwa2V0dGxlfGVufDB8fDB8fHww",
  },
];

export const DISCOUNTED_PET_SHOP = [
  {
    id: 1,
    product_name: "Dogs",
    image:
      "https://images.unsplash.com/photo-1517849845537-4d257902454a?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8cGV0fGVufDB8fDB8fHww",
  },
  {
    id: 2,
    product_name: "Dogs",
    offer: "Your pup at 40% Off",
    image:
      "https://images.unsplash.com/photo-1517849845537-4d257902454a?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8cGV0fGVufDB8fDB8fHww",
  },
  {
    id: 3,
    product_name: "Cats",
    offer: "Your kitty at 30% Off",
    image:
      "https://images.unsplash.com/photo-1548366086-7f1b76106622?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTV8fGNhdHN8ZW58MHx8MHx8fDA%3D",
  },
  {
    id: 4,
    product_name: "Pet Food",
    offer: "Flat 20% Off",
    image:
      "https://m.media-amazon.com/images/I/61c-pIm5oRL._AC_UF894,1000_QL80_.jpg",
  },
  {
    id: 5,
    product_name: "Pet Accessories",
    offer: "Flat 20% Off",
    image:
      "https://images.unsplash.com/photo-1577611612331-845d0e3d77af?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTF8fFBldCUyMGFjY2Vzc29yaWVzfGVufDB8fDB8fHww",
  },
];

export const FILTERS_CONTENT = [
  {
    id: 1,
    title: "Categories",
    category_filter: [
      "Coffee Mugs",
      "Air Fryer",
      "Electric Kettle",
      "Steamers",
      "Microwave",
    ],
  },
  {
    id: 2,
    title: "Price",
    category_filter: [
      "Rs 700 to Rs 1000",
      "Rs 1000 to Rs 1500",
      "Rs 1500 to Rs 1800",
      "Rs 1800 to Rs 2100",
      "Rs 2100 to Rs 3000",
      "Rs 3000+",
    ],
  },
  {
    id: 3,
    title: "Brand",
    category_filter: [
      "Philips",
      "Usha",
      "Household Fab",
      "Amaryllis",
      "Dream Appliances",
      "Dyson",
    ],
  },
  {
    id: 3,
    title: "Brand",
    category_filter: [
      "Philips",
      "Usha",
      "Household Fab",
      "Amaryllis",
      "Dream Appliances",
      "Dyson",
    ],
  },
  {
    id: 3,
    title: "Brand",
    category_filter: [
      "Philips",
      "Usha",
      "Household Fab",
      "Amaryllis",
      "Dream Appliances",
      "Dyson",
    ],
  },
  {
    id: 3,
    title: "Brand",
    category_filter: [
      "Philips",
      "Usha",
      "Household Fab",
      "Amaryllis",
      "Dream Appliances",
      "Dyson",
    ],
  },
  {
    id: 3,
    title: "Brand",
    category_filter: [
      "Philips",
      "Usha",
      "Household Fab",
      "Amaryllis",
      "Dream Appliances",
      "Dyson",
    ],
  },
  {
    id: 4,
    title: "Color",
    category_filter: ["Red", "Green", "Black", "Blue", "Beige", "SteelGrey"],
  },
];

export const CATEGORIES_CONTENT = [
  {
    type: "Ecommerce",
    main_categories: [
      {
        title: "Clothing",
        image: clothing_photo,
        link: "/clothing",
        type: "ecommerce",
      },
      {
        title: "Home Decor",
        image: home_decor,
        link: "/home_decor",
        type: "ecommerce",
      },
      {
        title: "Home Appliances",
        image:
          "https://media.istockphoto.com/id/1475226180/photo/air-fryer-machine-cooking-potato-fried-in-kitchen-lifestyle-of-new-normal-cooking.webp?b=1&s=612x612&w=0&k=20&c=Qk7oNP6RL8Q4Ymi3PJxnz8d1dBm4j2N_l76hfje9CWM=",
        link: "/home_appliances",
        type: "ecommerce",
        category_id: 11,
      },
      {
        title: "Jewellery",
        image:
          "https://plus.unsplash.com/premium_photo-1674255466836-f38d1cc6fd0d?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8SmV3ZWxsZXJ5fGVufDB8fDB8fHww",
        link: "/jewellery",
        type: "ecommerce",
      },
    ],
  },
  {
    type: "Listing",
    main_categories: [
      { title: "Pets", image: pet_banner, link: "/pets", type: "ecommerce" },
      {
        title: "Automobile",
        image: automobile,
        link: "/automobile",
        type: "listing",
      },
      {
        title: "Real Estate",
        image:
          "https://plus.unsplash.com/premium_photo-1680582107403-04dfac02efc3?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8YnVpbGRpbmdzfGVufDB8fDB8fHww",
        link: "/real_estate",
        type: "listings",
      },
      {
        title: "Restaurants",
        image:
          "https://images.unsplash.com/photo-1514933651103-005eec06c04b?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8cmVzdGF1cmFudHxlbnwwfHwwfHx8MA%3D%3D",
        link: "/restaurants",
        type: "listings",
      },
      {
        title: "Wedding Venues",
        image:
          "https://images.unsplash.com/flagged/photo-1572534779127-b64758946728?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8SW5kaWFuJTIwV2VkZGluZ3xlbnwwfHwwfHx8MA%3D%3D",
        link: "/wedding_venues",
        type: "listings",
      },
    ],
  },
  {
    type: "Services",
    main_categories: [
      {
        title: "Doctors",
        image:
          "https://plus.unsplash.com/premium_photo-1661766718556-13c2efac1388?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8RG9jdG9yc3xlbnwwfHwwfHx8MA%3D%3D",
        link: "/doctors",
        type: "listings",
      },
      {
        title: "Interior Designer",
        image:
          "https://images.unsplash.com/photo-1615875221248-d4b820203f97?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8SW50ZXJpb3IlMjBEZXNpZ25lcnxlbnwwfHwwfHx8MA%3D%3D",
        link: "/interior_designer",
        type: "listings",
      },
      {
        title: "Content Creators",
        image:
          "https://images.unsplash.com/photo-1612311371324-6fb6f8c6f325?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8Q29udGVudCUyMENyZWF0b3JzfGVufDB8fDB8fHww",
        link: "/content_creators",
        type: "listings",
      },
      {
        title: "Saloon and Spa",
        image:
          "https://images.unsplash.com/photo-1612311371324-6fb6f8c6f325?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8Q29udGVudCUyMENyZWF0b3JzfGVufDB8fDB8fHww",
        link: "/content_creators",
        type: "listings",
      },
      {
        title: "Electrician",
        image:
          "https://images.unsplash.com/photo-1612311371324-6fb6f8c6f325?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8Q29udGVudCUyMENyZWF0b3JzfGVufDB8fDB8fHww",
        link: "/content_creators",
        type: "listings",
      },
      {
        title: "Plumber",
        image:
          "https://images.unsplash.com/photo-1612311371324-6fb6f8c6f325?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8Q29udGVudCUyMENyZWF0b3JzfGVufDB8fDB8fHww",
        link: "/content_creators",
        type: "listings",
      },
      {
        title: "Electrician",
        image:
          "https://images.unsplash.com/photo-1612311371324-6fb6f8c6f325?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8Q29udGVudCUyMENyZWF0b3JzfGVufDB8fDB8fHww",
        link: "/content_creators",
        type: "listings",
      },
    ],
  },
];

export const SHOW_LIST = {
  clothing: CLOTHING,
  automobile: AUTOMOBILE,
  pets: PET_SHOP,
  home_appliances: KITCHEN_APPLIANCES,
};

export const CLIENT_BRAND_COLOR = {
  // HOME_APPLIANCES: {
  //   primary: "#fccd04",
  //   secondary: "#ffffff",
  //   tertiary: "#000000",
  // },
  // INTERIOR_DESIGNER: {
  //   primary: "#86C232",
  //   secondary: "#ffffff",
  //   tertiary: "#474b4f",
  // },
  // DOCTORS: {
  //   primary: "#ffffff",
  //   secondary: "#3d5a80",
  //   tertiary: "#293241",
  // },

  ["wayne-cars"]: {
    primary: "#fccd04",
    secondary: "#ffffff",
    tertiary: "#000000",
  },
  ["feline-friends"]: {
    primary: "#86C232",
    secondary: "#ffffff",
    tertiary: "#474b4f",
  },
  "kent-apparels": {
    primary: "#ffffff",
    secondary: "#3d5a80",
    tertiary: "#293241",
  },
};
