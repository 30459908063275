import styled from "styled-components";

export const HomepageProductListingWrapper = styled.div`
  margin: 104px 0px;
`;

export const ProductHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ListingWrapper = styled.div`
  margin-top: 54px;
  display: flex;

  @media only screen and (max-width: 768px) {
    overflow-x: scroll;
    scrollbar-width: none;
    gap: 40px;
  }
`;
