import React from "react";
import {
  StyledMedium,
  StyledXL,
  StyledXLPlus,
} from "../../styles/CommonStyles";
import { OfferingsHeader, WhatWeOfferWrapper } from "./WhatWeOfferStyles";
import OfferingCard from "./OfferingCard";

const WhatWeOffer = () => {
  return (
    <WhatWeOfferWrapper>
      <OfferingsHeader>
        <StyledXLPlus style={{ fontWeight: "900" }}>Our Offerings</StyledXLPlus>
        <StyledMedium>
          Looking for something? <span>Don't worry we have your back.</span>
        </StyledMedium>
      </OfferingsHeader>
      <div style={{ position: "relative" }}>
        <OfferingCard />
      </div>
    </WhatWeOfferWrapper>
  );
};

export default WhatWeOffer;
