import styled from "styled-components";

export const FirstWrapper = styled.div`
  position: relative;
  right: 2px;
`;

export const FirstFoldWrapper = styled.div`
  background: #fee715ff;
  height: 100vh;
  padding: 0px 56px;
`;

export const SlideWrapper = styled.div`
  display: flex !important;
  cursor: pointer;
  @media (max-width: 768px) {
    display: block !important;
  }
`;

export const VideoWrapper = styled.div`
  height: 70vh;
  width: 800px;
  flex: 1;
  position: relative;
  right: 8px;
  margin: 0;
  @media (max-width: 768px) {
    height: 20vh;
    width: 100vw;
    right: 8px;
  }
`;

export const ImageWrapper = styled.img`
  height: 100%;
  width: 100%;
`;

export const ImageContainer = styled.div`
  height: 82vh;
  width: 50%;
  position: relative;
  z-index: 1;
  @media (max-width: 768px) {
    height: 40vh;
    width: 100vw;
    left: 0;
    margin-top: 100px;
  }
`;
