import React, { useEffect } from "react";
import FirstFold from "../components/FirstFold/FirstFold";
import SecondFold from "../components/SecondFold/SecondFold";
import ThirdFold from "../components/ThirdFold/ThirdFold";
import FourthFold from "../components/FourthFold/FourthFold";
import FifthFold from "../components/FifthFold/FifthFold";
import ServicesFold from "../components/ServicesFold/ServicesFold";
import axios from "axios";

const Home = ({ page_type }) => {
  const fun = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_ORANGE_URL}/v1/home/categories`
    );
    console.log("result101", res);
  };

  useEffect(() => {
    fun();
  }, []);

  return (
    <div>
      <FirstFold page_type={page_type} />
      <SecondFold page_type={page_type} />
      <ThirdFold />
      {page_type != "services" ? <FourthFold /> : <ServicesFold />}
      <FifthFold />
    </div>
  );
};

export default Home;
