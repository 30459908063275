import styled from "styled-components";

export const FourthFoldWrapper = styled.div`
  overflow: hidden;
  margin-top: 100px;
`;

export const StyledBanner = styled.div`
  margin: 104px 0px 0px;
`;
