import React, { useEffect, useState } from "react";
import Footer from "../Footer/Footer";
import Testimonials from "../Testimonials/Testimonials";
import Card from "../Testimonials/Card";
import doctor from "@/assets/doctor.png";
import TestimonialCard from "../TestimonialCard/TestimonialCard";

const FifthFold = ({ client }) => {
  const [testimonialSlides, setTestimonialSlides] = useState([]);

  let cards = [
    {
      key: 1,

      image: doctor,
      name: "Rishabh Gupta",
      description:
        "I've been using [Product/Service Name] for the past six months, and I couldn't be happier with the results. The [specific feature or aspect] has been a game-changer for our team, streamlining our workflow and increasing our productivity. The customer support is outstanding—every time we've had a question or issue, the team has been quick to respond and incredibly helpful. I highly recommend [Product/Service Name] to anyone looking to improve their [specific need or area]. It's truly made a difference for us!",
    },
    {
      key: 1,

      image: doctor,
      name: "Rishabh Gupta",
      description:
        "I've been using [Product/Service Name] for the past six months, and I couldn't be happier with the results. The [specific feature or aspect] has been a game-changer for our team, streamlining our workflow and increasing our productivity. The customer support is outstanding—every time we've had a question or issue, the team has been quick to respond and incredibly helpful. I highly recommend [Product/Service Name] to anyone looking to improve their [specific need or area]. It's truly made a difference for us!",
    },
    {
      key: 1,

      image: doctor,
      name: "Rishabh Gupta",
      description:
        "I've been using [Product/Service Name] for the past six months, and I couldn't be happier with the results. The [specific feature or aspect] has been a game-changer for our team, streamlining our workflow and increasing our productivity. The customer support is outstanding—every time we've had a question or issue, the team has been quick to respond and incredibly helpful. I highly recommend [Product/Service Name] to anyone looking to improve their [specific need or area]. It's truly made a difference for us!",
    },
    {
      key: 1,

      image: doctor,
      name: "Rishabh Gupta",
      description:
        "I've been using [Product/Service Name] for the past six months, and I couldn't be happier with the results. The [specific feature or aspect] has been a game-changer for our team, streamlining our workflow and increasing our productivity. The customer support is outstanding—every time we've had a question or issue, the team has been quick to respond and incredibly helpful. I highly recommend [Product/Service Name] to anyone looking to improve their [specific need or area]. It's truly made a difference for us!",
    },
    {
      key: 1,
      image: doctor,
      name: "Rishabh Gupta",
      description:
        "I've been using [Product/Service Name] for the past six months, and I couldn't be happier with the results. The [specific feature or aspect] has been a game-changer for our team, streamlining our workflow and increasing our productivity. The customer support is outstanding—every time we've had a question or issue, the team has been quick to respond and incredibly helpful. I highly recommend [Product/Service Name] to anyone looking to improve their [specific need or area]. It's truly made a difference for us!",
    },
  ];

  const fetchCards = () => {
    let slides = cards?.map((testimonial) => {
      return (
        <>
          <TestimonialCard testimonial={testimonial} />
        </>
      );
    });
    setTestimonialSlides(slides);
  };

  useEffect(() => {
    fetchCards();
  }, []);

  return (
    <div>
      <Testimonials client={client} slides={testimonialSlides} />
      <Footer />
    </div>
  );
};

export default FifthFold;
