import Styles from "./Card.module.css";
import React, { useState } from "react";
import { useSpring, animated } from "react-spring";
// import Button from "./Button";

function Card({ imagen, name, description }) {
  const [show, setShown] = useState(false);

  const props3 = useSpring({
    opacity: 1,
    transform: show ? "scale(1.03)" : "scale(1)",
    boxShadow: show
      ? "0 20px 25px rgb(0 0 0 / 25%)"
      : "0 2px 10px rgb(0 0 0 / 4%)",
  });

  return (
    <animated.div
      className={Styles.card}
      style={props3}
      onMouseEnter={() => setShown(true)}
      onMouseLeave={() => setShown(false)}
    >
      <>
        <img src={imagen} alt="" />
        <h2>{name}</h2>
        <p
          style={{
            height: "130px",
            overflow: "scroll",
            scrollbarWidth: "none",
          }}
        >
          {description}
        </p>
      </>
    </animated.div>
  );
}

export default Card;
