import React from "react";
import { ThirdFoldWrapper } from "./ThirdFoldStyles";
import WhatWeOffer from "../WhatWeOffer/WhatWeOffer";

const ThirdFold = () => {
  return (
    <ThirdFoldWrapper>
      <WhatWeOffer />
    </ThirdFoldWrapper>
  );
};

export default ThirdFold;
