import styled from "styled-components";

export const ClientSecondFoldWrapper = styled.div`
  background: #e7f0f8;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 24px;
`;
