import React from "react";
import { IconContainer, Input, InputContainer } from "./InputBoxStyles";

const InputBox = ({ placeholder, icon, type, onChange, styles }) => {
  return (
    <InputContainer>
      <Input
        type={type}
        onChange={onChange}
        placeholder={placeholder}
        background={styles?.background}
        color={styles?.color}
        padding={styles.padding}
        minWidth={styles?.minWidth}
        maxWidth={styles?.maxWidth}
        borderBottom={styles.borderBottom}
        borderRadius={styles?.borderRadius}
      />
      <IconContainer right={styles.right} top={styles.top}>
        {icon}
      </IconContainer>
    </InputContainer>
  );
};

export default InputBox;
