import styled from "styled-components";

export const LeftPane = styled.div`
  background: #000000;
  height: 83vh;
  width: 50%;
  &.premium-right-arrow {
    position: absolute;
    &:hover {
      color: white;
    }
  }
`;

export const RightPane = styled.div`
  //   background: rgba(20, 20, 20, 0.9);
  background: #000000;
  height: 83vh;
  width: 50%;
`;

export const PartnerButtonWrapper = styled.button`
  background: white;
  border: 1px solid black;
  border-radius: 6px;
  padding: 16px 24px 16px 54px;
  font-size: 18px;
  color: black;
  font-weight: 900;
  cursor: pointer;
  position: relative;

  &:hover {
    background: black;
    color: white;
  }

  @media only screen and (max-width: 768px) {
    font-size: 14px;
    padding: 16px 0px 16px 0px;
    width: 158px;
  }
`;

export const ShopNowButtonWrapper = styled.button`
  border: 1px solid grey;
  background: white;
  border-radius: 6px;
  padding: 12px 12px;

  font-size: 18px;
  color: black;
  font-weight: 900;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  &:hover {
    background: black;
    border: 1px solid grey;
    color: white;
  }
`;

export const PremiumRightArrow = styled.div`
  position: absolute;
  left: 4%;
  top: 16%;
  animation: moveUpDown 1s infinite;
  @keyframes moveUpDown {
    0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(-10px);
    }
    100% {
      transform: translateY(0);
    }
  }
`;

export const HeaderContainer = styled.div`
  width: 100%;
  margin: 0px auto;
  text-align: center;
`;
