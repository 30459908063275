import React from "react";
import Slider from "react-slick";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const CustomSlider = ({ slides, landingFold, settings }) => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 10,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: landingFold == "first" ? 1 : 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: landingFold == "first" ? 1 : 1.2,
    },
  };

  if (slides) {
    return (
      <Carousel
        responsive={responsive}
        {...settings}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
        style={{ zIndex: "-1" }}
      >
        {slides?.map((slide) => slide)}
      </Carousel>
    );
  }
};

export default CustomSlider;
