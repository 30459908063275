import React, { useEffect, useState } from "react";
import { FourthFoldWrapper, StyledBanner } from "./FourthFoldStyles";
import HomepageProductListing from "../HomepageProductListing/HomepageProductListing";
import DiscountedCard from "../DiscountedCard/DiscountedCard";
import ContinuousTextLine from "../ContinuousTextLine/ContinuousTextLine";
import axios from "axios";
import { useSelector } from "react-redux";

const FourthFold = () => {
  const { categoryIdAndNames } = useSelector((state) => state.HomepageReducer);
  const [results, setResults] = useState({});

  const fetchProducts = async (id, name) => {
    try {
      const appliances = await axios.get(
        `${process.env.REACT_APP_ORANGE_URL}/v1/home/category-section`
      );
      setResults(appliances?.data?.data);
    } catch (error) {
      return;
    }
  };

  console.log("aplliance", results);

  useEffect(() => {
    if (Object.keys(categoryIdAndNames).length > 0) {
      fetchProducts(categoryIdAndNames["Houseware"], "Houseware");
    }
  }, [categoryIdAndNames]);

  if (Object.keys(results).length > 0) {
    return (
      <FourthFoldWrapper>
        <ContinuousTextLine content="At Orange you will find all products and services under one roof. From premium to affordable we have it all. Slay in style, get services on the doorstep. Orange has partnered with range of service providers in sectors like healthcare, farms, electrician, plumber, wholesellers and much more." />
        <HomepageProductListing
          products={results[0]}
          header="Kitchen Appliances"
        />
        <div style={{ marginBottom: "44px" }}>
          <DiscountedCard
            products={results?.[0]?.highest_discounted_products}
            category={results?.[0]?.category}
            sequence="2"
          />
        </div>

        {/* <StyledBanner>
          <PhotoBanner bannerImage={petshop} />
        </StyledBanner>

        <HomepageProductListing products={PET_SHOP} header="Pet Shop" />
        <DiscountedCard products={DISCOUNTED_PET_SHOP} sequence="1" />
        <StyledBanner>
          <PhotoBanner bannerImage="https://img.freepik.com/free-psd/fashion-model-banner-template_23-2148858371.jpg?w=2000&t=st=1723732888~exp=1723733488~hmac=479b4e35b71956ba19787bb3c9a4908d80a85e333143024ac478df2cf62c1522" />
        </StyledBanner>
        <HomepageProductListing products={CLOTHING} header="Clothing" />
        <DiscountedCard products={DISCOUNTED_CLOTHING} sequence="2" /> */}
        <ContinuousTextLine content="We at orange are dedicated to bring the best shopping experience. We thrive to create a huge network of buyers, sellers, wholesellers, dealers and much more. We at orange are curious set of engineers who are obsessive over bringing the quality services at your doorstep." />
      </FourthFoldWrapper>
    );
  }
};

export default FourthFold;
