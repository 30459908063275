import React from "react";
import {
  AlignTitle,
  CardImageContainer,
  CardWrapper,
} from "./CategoriesStyles";
import { StyledMedium } from "../../styles/CommonStyles";

const CategoryCard = ({ content, onClick }) => {
  return (
    <CardWrapper onClick={onClick}>
      <CardImageContainer>
        <img src={content?.icon_url} alt={content.display_name} />
      </CardImageContainer>
      <AlignTitle>
        <StyledMedium style={{ cursor: "pointer" }}>
          {content?.display_name}
        </StyledMedium>
      </AlignTitle>
    </CardWrapper>
  );
};

export default CategoryCard;
