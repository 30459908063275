import styled from "styled-components";

export const BannerWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 12px auto;
`;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

export const StyleButtonOnImage = styled.div`
  position: absolute;
  bottom: 43%;
  background: black;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  padding: 24px 0px;

  @media only screen and (max-width: 768px) {
    bottom: 75%;
  }
`;
