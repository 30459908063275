const types = {
  SET_NAV_CONTENT: "SET_NAV_CONTENT",
  SET_SINGLE_PRODUCT_DETAILS: "SET_SINGLE_PRODUCT_DETAILS",
  SET_CLIENT_DATA: "SET_CLIENT_DATA",
  SET_EACH_CATEGORY_PRODUCT: "SET_EACH_CATEGORY_PRODUCT",
  SET_CATEGORY: "SET_CATEGORY",
  SET_CLIENT_BRAND_COLOR: "SET_CLIENT_BRAND_COLOR",
  SET_PRODUCT_DATA_AFTER_NAVIGATION: "SET_PRODUCT_DATA_AFTER_NAVIGATION",
  SET_CLIENT_RESPONSE_SCHEMA: "SET_CLIENT_RESPONSE_SCHEMA",
};

export default types;
