import React from "react";
import { Box, ContactDetails, ContactFoldWrapper } from "./ContactFoldStyles";
import { Styled2XL, StyledMedium, StyledXL } from "../../styles/CommonStyles";

const ContactFold = ({ client }) => {
  return (
    <ContactFoldWrapper>
      <Styled2XL style={{ color: "white" }}>Contact Us</Styled2XL>
      <ContactDetails>
        <Box>
          <StyledMedium style={{ color: "white" }}>Address</StyledMedium>
          <br />
          <StyledXL style={{ color: "white" }}>{client?.address}</StyledXL>
        </Box>
        <Box>
          <StyledMedium style={{ color: "white" }}>
            Contact Numbers
          </StyledMedium>
          <br />
          <StyledXL style={{ color: "white" }}>{client?.phone_number}</StyledXL>
          <StyledXL style={{ color: "white" }}>
            {client?.whatsapp_number}
          </StyledXL>
        </Box>
        <Box>
          <StyledMedium style={{ color: "white" }}>Email us at</StyledMedium>
          <StyledXL style={{ color: "white", marginTop: "12px" }}>
            {client?.email}
          </StyledXL>
        </Box>
      </ContactDetails>
      {/* {client?.list_of_branches?.length > 0 ? <StyledMedium></StyledMedium>} */}
    </ContactFoldWrapper>
  );
};

export default ContactFold;
