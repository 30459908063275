import styled from "styled-components";

export const ListingCard = styled.div(({ width = "150px" }) => ({
  height: "184px",
  width: width,

  img: {
    height: "100%",
    width: "100%",
    borderRadius: "12px",
  },
}));

export const ListingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 72px 56px 0px;
  flex-wrap: wrap;
  height: 100%;
  gap: 40px 24px;
`;

export const QuantityButton = styled.button`
  padding: 2px 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;
  cursor: pointer;
`;

export const QuantityContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const BuyButtonContainer = styled.div``;

export const ShopNowContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const BuyNowButton = styled.button`
  border: 1px solid grey;
  background: white;
  border-radius: 2px;
  padding: 4px 6px;
  font-size: 14px;
  color: black;
  font-weight: 900;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  &:hover {
    background: black;
    border: 1px solid grey;
    color: white;
  }
`;
