import React, { useEffect, useState } from "react";
import {
  HomepageProductListingWrapper,
  ListingWrapper,
  ProductHeader,
} from "./HomepageProductListingStyles";
import ProductCard from "../common/ProductCard/ProductCard";
import { StyledXLPlus } from "../../styles/CommonStyles";
import CustomSlider from "../common/CustomSlider";
import { screenWidth } from "../utilities";

const HomepageProductListing = ({ products, header }) => {
  const [productSlides, setProductSlides] = useState([]);
  const screen = screenWidth();

  const settings = {
    autoPlay: true,
    swipeable: true,
    draggable: true,
    showDots: false,
    infinite: true,
    partialVisible: true,
    onHover: true,
  };

  const fetchSlides = () => {
    let slides = products?.category?.sub_categories?.map((product) => {
      return (
        <>
          <ProductCard product={product} parentCategory={products.category} />
        </>
      );
    });
    setProductSlides(slides);
  };

  useEffect(() => {
    fetchSlides();
  }, []);

  console.log("productSlides", productSlides);
  return (
    <HomepageProductListingWrapper>
      <ProductHeader>
        <StyledXLPlus>{header}</StyledXLPlus>
      </ProductHeader>
      <ListingWrapper>
        {productSlides && screen !== "mobile" ? (
          <CustomSlider
            settings={settings}
            slides={productSlides}
            landingFold=""
          />
        ) : (
          productSlides.map((slide) => slide)
        )}
      </ListingWrapper>
    </HomepageProductListingWrapper>
  );
};

export default HomepageProductListing;
