import React, { useEffect, useState } from "react";
import Navbar from "../Navbar/Navbar";
import MegaMenu from "../Navbar/MegaMenu";
import Footer from "../Footer/Footer";
import CartProduct from "../CartProduct/CartProduct";
import axios from "axios";

const Cart = () => {
  const product = window.location.href.split("/");
  const [cartList, setCartList] = useState([]);
  console.log("product101", product?.[product.length - 1]);

  const fetchCartData = async () => {
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_ORANGE_URL}/v1/product/${
          product?.[product.length - 1]
        }`
      );
      console.log("result101", result.data.data);
      setCartList(result?.data?.data);
    } catch (error) {
      return;
      // return <FallbackUI />;
    }
  };

  useEffect(() => {
    fetchCartData();
  }, []);

  console.log("cartList", cartList);
  if (cartList && Object.keys(cartList)?.length > 0) {
    return (
      <div>
        <Navbar />
        <div style={{ position: "sticky", top: "100px", zIndex: 10 }}>
          <MegaMenu />
        </div>

        <CartProduct cartList={cartList} />
        <div style={{ marginTop: "24px" }}>
          <Footer />
        </div>
      </div>
    );
  }
};

export default Cart;
