import styled from "styled-components";

export const CategoriesWrapper = styled.div`
  margin-top: 64px;
`;

export const CardWrapper = styled.div`
  transition: all 0.2s ease-in-out;
  &:hover {
    scale: 1.1;
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    img {
      box-shadow: 0 0 15px #d35400;
    }
  }
`;

export const CategoryContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 24px 32px;
  gap: 40px;
  flex-wrap: wrap;
  flex-basis: 33.33%;

  @media screen and (max-width: 768px) {
    flex-wrap: nowrap;
    overflow-x: scroll;
    scrollbar-width: none;
  }
`;

export const CategoryTitleContainer = styled.div`
  padding: 12px 32px;
  background: black;
  display: flex;
  justify-content: center;
`;

export const CategoryHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 6px;
  margin-bottom: 48px;
`;

export const CardImageContainer = styled.div`
  height: 200px;
  width: 200px;

  img {
    height: 100%;
    width: 100%;
    border-radius: 12px;
    cursor: pointer;
  }
`;

export const AlignTitle = styled.div`
  margin: 12px 6px 12px;
`;
