import styled from "styled-components";

export const OfferingsWrapper = styled.div`
  display: flex;
  gap: 12px;
  justify-content: space-evenly;
  padding: 24px;
  background: #f5f5f5;
`;

export const StyledOffering = styled.div`
  display: flex;
  gap: 12px;
`;
