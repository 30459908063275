import React, { useState, useEffect } from "react";
import { SHOW_LIST } from "../../constants";
import {
  BuyButtonContainer,
  BuyNowButton,
  ListingCard,
  ListingContainer,
  QuantityButton,
  QuantityContainer,
  ShopNowContainer,
} from "./ProductListingsStyles";
import { StyledMedium, StyledSmall } from "../../styles/CommonStyles";
import { FaWhatsapp } from "react-icons/fa";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import types from "@/redux/types";
import axios from "axios";
import { useSearchParams } from "react-router-dom";

const ProductListing = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const parentName = searchParams.get("parent_category_name");
  const category_id = searchParams.get("category_id");
  const urlParams = window.location.pathname.split("/")?.[1];
  const [productList, setProductList] = useState([]);
  const page_type = localStorage.getItem("page_type");
  const { selectedProduct } = useSelector((state) => state.HomepageReducer);

  const fetchProductByCategoriesFilter = async () => {
    try {
      if (selectedProduct?.length > 0) {
        setProductList(selectedProduct);
      } else {
        const selectedUrl =
          category_id && parentName
            ? `${
                process.env.REACT_APP_ORANGE_URL
              }/v1/search/results?category_id=${category_id}&parent_category_name=${
                parentName ?? ""
              }`
            : `${
                process.env.REACT_APP_ORANGE_URL
              }/v1/search/results?parent_category_name=${parentName ?? ""}`;

        const result = await axios.get(selectedUrl);
        setProductList(result?.data?.data);
      }
    } catch (error) {
      return;
    }
  };

  useEffect(() => {
    fetchProductByCategoriesFilter();
  }, [category_id, parentName]);

  return (
    <ListingContainer>
      {productList?.map((product) => {
        console.log("porducc", product.product);
        return (
          <div
            style={{ display: "flex", flexDirection: "column", width: "200px" }}
          >
            <ListingCard
              width={urlParams == "automobile" ? "274px" : "176px"}
              className="img-area single-img"
            >
              <img
                src={
                  product?.type == "listing"
                    ? product?.listing?.media_path
                    : product?.product?.media?.[0]?.path
                }
                alt={
                  product?.type == "listing"
                    ? product?.listing?.name
                    : product?.product?.name
                }
                className="image"
              />
              <div class="img-overlay">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "6px 12px",
                  }}
                >
                  <StyledSmall>
                    <span style={{ color: "white" }}>In stock</span>
                  </StyledSmall>
                  <StyledSmall style={{ color: "white" }}>
                    {product?.product?.quantity}
                  </StyledSmall>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "0px 12px",
                    position: "relative",
                    top: "14px",
                  }}
                >
                  <BuyNowButton
                    onClick={() => {
                      dispatch({
                        type: types.SET_SINGLE_PRODUCT_DETAILS,
                        payload: { product_details: product },
                      });
                      navigate(`/cart/${product?.product?.code}`);
                    }}
                  >
                    More
                  </BuyNowButton>
                </div>
              </div>
            </ListingCard>
            <div style={{ margin: "6px 0" }}>
              <StyledSmall>
                {product?.type == "listing" ? (
                  <strong>{product?.listing?.name}</strong>
                ) : (
                  product?.product?.name
                )}
              </StyledSmall>
            </div>

            {page_type === "listing" ? (
              <StyledSmall style={{ marginBottom: "16px" }}>
                {product?.product?.description}
              </StyledSmall>
            ) : (
              <div style={{ display: "flex", gap: "8px" }}>
                {product?.type == "listing" ? (
                  <StyledSmall style={{ color: "#36454F" }}>
                    {product?.listing?.description}
                  </StyledSmall>
                ) : (
                  <StyledMedium style={{ marginBottom: "6px" }}>
                    &#8377; {product?.product?.discounted_price}
                  </StyledMedium>
                )}
              </div>
            )}

            <ShopNowContainer>
              <QuantityContainer>
                {parentName != "restaurants" ? (
                  <QuantityButton>
                    <FaWhatsapp
                      size={20}
                      color="green"
                      onClick={() =>
                        window.open(
                          `https://wa.me/${product?.listing?.whatsapp_number}?text=${product?.default_message_from_client}`,
                          "__blank"
                        )
                      }
                    />
                  </QuantityButton>
                ) : null}
              </QuantityContainer>
              <BuyButtonContainer>
                <BuyNowButton
                  onClick={() => navigate(`/${product?.client_code}`)}
                >
                  Visit Shop
                </BuyNowButton>
              </BuyButtonContainer>
            </ShopNowContainer>
          </div>
        );
      })}
      {/* <Pagination items={productList} itemsPerPage={5} />; */}
    </ListingContainer>
  );
};

export default ProductListing;
