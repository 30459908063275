import React, { useState, useEffect } from "react";
import {
  CTAIconsContainer,
  ClientInputContainer,
  CompanyInfoContainer,
  LogoContainer,
  NavChild,
  NavContent,
  NavItem,
  NavbarWrapper,
} from "./ClientNavbarStyles";
import company_logo from "@/assets/company_logo.png";
import { NAV_CONTENT } from "@/constants";
import { StyledMedium } from "@/styles/CommonStyles";
import { IoCartOutline } from "react-icons/io5";
import { IoMdHeartEmpty } from "react-icons/io";
import { FiFacebook } from "react-icons/fi";
import { FaInstagram } from "react-icons/fa6";
import { AiOutlineYoutube } from "react-icons/ai";
import { GoSearch } from "react-icons/go";
import InputBox from "../common/InputBox/InputBox";
import { useDispatch } from "react-redux";
import types from "@/redux/types";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { CLIENT_BRAND_COLOR } from "../../constants";
import { SearchContent, SearchResultsContainer } from "../Navbar/NavbarStyles";

const ClientNavbar = ({ page_type, client }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [navbarData, setNavbarData] = useState([]);
  const [colors, setColors] = useState({});
  const [searchResult, setSearchResult] = useState([]);

  console.log("client101", client);
  const fetchNavbar = async () => {
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_ORANGE_URL}/v1/home/navbar?client_id=${client.id}`
      );
      setNavbarData(result?.data?.data);
    } catch (error) {
      return;
      // return <FallbackUI />;
    }
  };

  useEffect(() => {
    fetchNavbar();
  }, []);

  const params = window.location.pathname.split("/")?.[1];
  useEffect(() => {
    dispatch({
      type: types.SET_CLIENT_BRAND_COLOR,
      payload: {
        primary: CLIENT_BRAND_COLOR?.[params]?.primary,
        secondary: CLIENT_BRAND_COLOR?.[params]?.secondary,
        tertiary: CLIENT_BRAND_COLOR?.[params]?.tertiary,
      },
    });
    setColors({
      primary: CLIENT_BRAND_COLOR?.[params]?.primary,
      secondary: CLIENT_BRAND_COLOR?.[params]?.secondary,
      tertiary: CLIENT_BRAND_COLOR?.[params]?.tertiary,
    });
  }, []);

  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(context, args), wait);
    };
  }

  const handleSearch = async (e) => {
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_ORANGE_URL}/v1/search?keyword=${e.target.value}&client_id=${client?.id}`
      );
      setSearchResult(result?.data?.data);
    } catch (error) {
      return;
      // return <FallbackUI />;
    }
  };

  return (
    <>
      <NavbarWrapper backgroundColor={colors?.primary}>
        <NavChild>
          <LogoContainer onClick={() => navigate("/")}>
            <img src={company_logo} alt="company logo" />
          </LogoContainer>
        </NavChild>

        <NavChild>
          <NavContent>
            {navbarData?.map((navData) => {
              return (
                <NavItem
                  onMouseOver={() =>
                    dispatch({
                      type: types.SET_NAV_CONTENT,
                      payload: {
                        menu: navData,
                        showMenu: true,
                      },
                    })
                  }
                  onMouseOut={() =>
                    dispatch({
                      type: types.SET_NAV_CONTENT,
                      payload: {
                        menu: navData,
                        showMenu: false,
                      },
                    })
                  }
                >
                  <StyledMedium
                    style={{
                      color: colors?.tertiary,
                      fontWeight: 900,
                      cursor: "pointer",
                    }}
                  >
                    {navData?.display_name}
                  </StyledMedium>
                </NavItem>
              );
            })}
          </NavContent>
        </NavChild>

        <NavChild className="direction">
          <CTAIconsContainer>
            <CompanyInfoContainer className="align-social-icons">
              <FaInstagram size={24} color={colors.tertiary} />
              <AiOutlineYoutube size={24} color={colors.tertiary} />
              <FiFacebook size={24} color={colors.tertiary} />
            </CompanyInfoContainer>
            <CompanyInfoContainer>
              <IoCartOutline size={24} color={colors.tertiary} />
              <IoMdHeartEmpty
                size={24}
                color={page_type == "services" ? "#212121" : "white"}
              />
            </CompanyInfoContainer>
          </CTAIconsContainer>
          <ClientInputContainer>
            <InputBox
              onChange={debounce(handleSearch, 400)}
              placeholder="Looking for..."
              icon={<GoSearch color="white" size={18} />}
              type="text"
              styles={{
                background: page_type == "services" ? "#212121" : "white",
                padding: "12px 24px",
                color: page_type == "services" ? "white" : "#212121",
              }}
            />
            <SearchResultsContainer
              style={{ display: searchResult?.length > 0 ? "block" : "none" }}
            >
              {searchResult?.map((content) => {
                console.log("content123", content);
                return (
                  <SearchContent
                    onClick={() =>
                      navigate(
                        `/category/products?parent_category_name=${content?.parent_category_name}&category_id=${content.category_id}`
                      )
                    }
                  >
                    {content.category_name}
                  </SearchContent>
                );
              })}
            </SearchResultsContainer>
          </ClientInputContainer>
        </NavChild>
      </NavbarWrapper>
    </>
  );
};

export default ClientNavbar;
