import React, { useState, useEffect } from "react";
import {
  CategoriesWrapper,
  CategoryContainer,
  CategoryHeader,
  CategoryTitleContainer,
} from "./CategoriesStyles";
import { StyledXLPlus } from "@/styles/CommonStyles";
import CategoryCard from "./CategoryCard";
import { StyledMedium } from "@/styles/CommonStyles";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { StyledXL } from "@/styles/CommonStyles";
import axios from "axios";
import types from "@/redux/types";
import CustomSlider from "../common/CustomSlider";
import { screenWidth } from "../utilities";

const Categories = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const screen = screenWidth();
  const [categoryData, setCategoryData] = useState({});
  const [mobileCategories, setMobileCategories] = useState([]);

  const fetchCategory = async () => {
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_ORANGE_URL}/v1/home/categories`
      );

      let updatedCategoryData = {
        ecommerce: [],
        listing: [],
        service: [],
      };

      let categoryIdAndNames = {};

      result?.data?.data?.map((item) => {
        categoryIdAndNames = {
          ...categoryIdAndNames,
          [item.display_name]: item.id,
          [item.id]: item.display_name,
        };
        if (item.category_type == "product") {
          updatedCategoryData?.ecommerce?.push(item);
        } else if (item.category_type == "listing") {
          updatedCategoryData?.listing?.push(item);
        } else {
          updatedCategoryData?.service?.push(item);
        }
      });
      dispatch({
        type: types?.SET_CATEGORY,
        payload: categoryIdAndNames,
      });
      setCategoryData(updatedCategoryData);
    } catch (error) {
      return;
      // return <FallbackUI />;
    }
  };

  useEffect(() => {
    fetchCategory();
  }, []);

  const fetchFold = (categoryValue) => {
    const categorySlides = categoryValue?.map((category, index) => {
      return (
        <CategoryCard
          key={index}
          content={category}
          onClick={() => {
            navigate(`category/products?category_id=4`);
          }}
        />
      );
    });

    console.log("categorySlides101", categorySlides);
    return <CustomSlider slides={categorySlides} landingFold="" />;
  };

  return (
    <CategoriesWrapper>
      <CategoryHeader>
        <StyledXLPlus>
          <strong>Categories</strong>
        </StyledXLPlus>
        <StyledMedium>
          <strong>We have something for everyone.</strong>
        </StyledMedium>
      </CategoryHeader>

      {Object.entries(categoryData)?.map(
        ([categoryKey, categoryValue], index) => {
          return (
            <div>
              {categoryValue.length > 0 ? (
                <CategoryTitleContainer>
                  <StyledXL style={{ color: "white" }}>
                    {categoryKey?.substring(0, 1).toUpperCase() +
                      categoryKey?.substring(1)}
                  </StyledXL>
                </CategoryTitleContainer>
              ) : null}

              <CategoryContainer>
                {/* {screen == "mobile"
                  ? fetchFold(categoryValue)
                  :  */}
                {categoryValue?.map((category) => {
                  return (
                    <CategoryCard
                      key={index}
                      content={category}
                      onClick={async () => {
                        navigate(
                          `category/products?parent_category_name=${category?.name}`
                        );
                      }}
                    />
                  );
                })}
              </CategoryContainer>
            </div>
          );
        }
      )}
    </CategoriesWrapper>
  );
};

export default Categories;
