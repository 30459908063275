import React from "react";
import { ProductContainer } from "./ProductFirstFoldStyles";
import Filter from "../Filter/Filter";
import ProductListing from "../ProductListings/ProductListing";

const ProductFirstFold = () => {
  return (
    <ProductContainer>
      <Filter />
      <ProductListing />
    </ProductContainer>
  );
};

export default ProductFirstFold;
