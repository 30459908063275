import React from "react";
import { StyledMedium } from "@/styles/CommonStyles";
import { useNavigate } from "react-router-dom";
import {
  AdjustMenuContentWidth,
  MegaMenuSearchContainer,
  MegaMenuWrapper,
  MenuContentWrapper,
  NavbarFooter,
  NavbarImageContainer,
} from "./NavbarStyles";
import { useSelector, useDispatch } from "react-redux";
import types from "../../redux/types";
import Card from "../common/Card";
import InputBox from "../common/InputBox/InputBox";
import { GoSearch } from "react-icons/go";
import { StyledXL } from "../../styles/CommonStyles";

const MegaMenu = () => {
  const { megamenu_content } = useSelector((state) => state.HomepageReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  console.log("navContent", megamenu_content);

  return (
    <MegaMenuWrapper
      className={`${megamenu_content?.showMenu ? "showCard" : null}`}
      onMouseOver={() =>
        dispatch({
          type: types.SET_NAV_CONTENT,
          payload: { ...megamenu_content, showMenu: true },
        })
      }
      onMouseOut={() =>
        dispatch({
          type: types.SET_NAV_CONTENT,
          payload: { ...megamenu_content, showMenu: false },
        })
      }
    >
      <MenuContentWrapper className="nav-menu-style">
        {megamenu_content?.menu?.sub_categories?.map((navContent) => {
          if (
            megamenu_content?.category_type == "listing" ||
            megamenu_content?.menu?.display_name == "Restaurants"
          ) {
            return (
              <MegaMenuSearchContainer>
                <StyledXL>{navContent?.offer}</StyledXL>
                <br />
                <InputBox
                  placeholder={navContent?.placeholder}
                  icon={<GoSearch color="white" size={24} />}
                  type="text"
                  styles={{
                    background: "black",
                    color: "white",
                    padding: "20px 44px 20px 20px",
                    minWidth: "50vw",
                    maxWidth: "100%",
                    right: "24px",
                    top: "14px",
                    borderRadius: "24px",
                    borderBottom: "2px solid yellow",
                  }}
                />
                <NavbarImageContainer>
                  <img src={navContent?.icon_url} />
                </NavbarImageContainer>

                <NavbarFooter>{navContent?.footer}</NavbarFooter>
              </MegaMenuSearchContainer>
            );
          }
          if (navContent?.category_type == "product") {
            return (
              <AdjustMenuContentWidth>
                <StyledMedium
                  style={{ color: "black", cursor: "pointer" }}
                  onClick={() =>
                    navigate(
                      `/category/products?parent_category_name=${megamenu_content?.menu?.name}&category_id=${navContent?.id}`
                    )
                  }
                  navContent={navContent}
                >
                  {navContent?.display_name}
                </StyledMedium>
              </AdjustMenuContentWidth>
            );
          }
        })}
      </MenuContentWrapper>
      <MenuContentWrapper className="menu-card-style">
        {megamenu_content?.menu?.sub_categories?.map((navContent) => {
          if (navContent?.type == "card") {
            return <Card title={navContent?.title}></Card>;
          }
        })}
      </MenuContentWrapper>
    </MegaMenuWrapper>
  );
};

export default MegaMenu;
