import React from "react";
import { SecondFoldWrapper } from "./SecondFoldStyles";
import Offerings from "../Offerings/Offerings";
import Categories from "../Categories/Categories";

const SecondFold = ({ page_type }) => {
  return (
    <SecondFoldWrapper>
      <Offerings />
      {page_type != "services" ? <Categories /> : null}
    </SecondFoldWrapper>
  );
};

export default SecondFold;
