import React, { useEffect, useRef } from "react";
import { ScrollObserver, valueAtPercentage } from "aatjs";
import {
  CardImageWrapper,
  ServiceContainer,
  ServicesCategoryContainer,
  ServicesImageContainer,
} from "./WhatWeOfferStyles";
import PartnerButton from "../common/PartnerButton";
import orange_verified from "@/assets/orange_verified.png";
import { HiCheck } from "react-icons/hi";
import { GoSearch } from "react-icons/go";
import InputBox from "../common/InputBox/InputBox";
import doctor from "@/assets/doctor.png";
import interior_designer from "@/assets/interior_designer.png";
import electrician from "@/assets/electrician.png";
import { StyledMedium, StyledSmall } from "../../styles/CommonStyles";
import { useNavigate } from "react-router";

const OfferingCard = () => {
  const navigate = useNavigate();

  const SERVICES = [
    { title: "Doctor", img: doctor, name: "doctor" },
    {
      title: "Interior Designer",
      img: interior_designer,
      name: "interior_designer",
    },
    { title: "Electrician", img: electrician, name: "electrician" },
    {
      title: "Saloon and Wellness",
      img: "https://welovespaservices.wordpress.com/wp-content/uploads/2013/02/beauty-salon.jpg",
      name: "saloon_and_wellness",
    },
    {
      title: "Mechanic",
      img: "https://autoaid.in/wp-content/uploads/2024/01/Breakdown-service-3.webp",
      name: "mechanic",
    },
    {
      title: "Wedding Planner",
      img: "https://badhaihoevents.in/wp-content/uploads/2022/07/mandap-fi-1.jpeg",
      name: "wedding_planner",
    },
    {
      title: "Content Creator",
      img: "https://www.socialpilot.co/wp-content/uploads/2023/08/Social-Media-Content-Creator.jpg",
      name: "content_creators",
    },
    {
      title: "AC Repair Services",
      img: "https://clareservices.com/wp-content/uploads/2021/05/technician-service-removing-air-filter-air-conditioner-cleaning_35076-3617-640x426.jpg",
      name: "ac_repair_services",
    },
  ];

  useEffect(() => {
    const cardsContainer = document.querySelector(".cards");
    const cards = document.querySelectorAll(".card");
    cardsContainer.style.setProperty("--cards-count", cards.length);
    cardsContainer.style.setProperty(
      "--card-height",
      `${cards[0].clientHeight}px`
    );
    Array.from(cards).forEach((card, index) => {
      const offsetTop = 20 + index * 20;
      card.style.paddingTop = `${offsetTop}px`;

      if (index == 2) {
        card.style.paddingTop = `${offsetTop}px`;
      }
      if (index === cards.length - 1) {
        return;
      }
      const toScale = 1 - (cards.length - 1 - index) * 0.1;
      const nextCard = cards[index + 1];
      const cardInner = card.querySelector(".card__inner");
      ScrollObserver.Element(nextCard, {
        offsetTop,
        offsetBottom: window.innerHeight - card.clientHeight,
      }).onScroll(({ percentageY }) => {
        cardInner.style.scale = valueAtPercentage({
          from: 1,
          to: toScale,
          percentage: percentageY,
        });
        cardInner.style.filter = `brightness(${valueAtPercentage({
          from: 1,
          to: 0.6,
          percentage: percentageY,
        })})`;
      });
    });
  });

  return (
    <>
      <div class="space space--small"></div>
      <div class="cards">
        <div class="card" data-index="0">
          <div class="card__inner">
            <div class="card__image-container">
              <img
                class="card__image"
                src="https://png.pngtree.com/background/20230524/original/pngtree-3-dimensional-cubes-inside-a-large-bright-orange-building-picture-image_2709821.jpg"
                alt=""
              />
            </div>
            <div class="card__content">
              <div class="orange_verified_container">
                <h1 class="card__title">Verified Partners</h1>
                <img src={orange_verified} alt="orange_verified" />
              </div>

              <p class="card__description">
                <div class="each_list_container">
                  <HiCheck size={40} color="#EFC14F" />
                  <div>We cover everything you need.</div>
                </div>
                <div class="each_list_container">
                  <HiCheck size={80} color="#EFC14F" />
                  <div>
                    We have our dedicated partners that are serving high quality
                    products right at your doorstep.
                  </div>
                </div>

                <div class="each_list_container">
                  <HiCheck size={50} color="#EFC14F" />
                  <div>
                    We keep 100% transparency between buyers and sellers.
                  </div>
                </div>
              </p>
            </div>
          </div>
        </div>
        <div class="card" data-index="0">
          <div class="card__inner">
            <div class="card__image-container">
              <img
                class="card__image"
                src="https://media.istockphoto.com/id/1300669114/vector/abstract-orange-background-geometric-texture.jpg?s=612x612&w=0&k=20&c=22ox6KgAYKMZCzmoyP665kQYBUtuoVRNvkfIrcJjgzU="
                alt=""
              />
            </div>
            <div class="card__content">
              <h1 class="card__title">Build brands</h1>
              <p class="card__description">
                <div>
                  <span
                    style={{
                      color: "#E94B3CFF",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <strong> Onboard as a partner now.</strong>
                    <PartnerButton />
                  </span>
                  <br />
                  We will take your business to new heights by
                  <br />
                  <li>Building your social media content</li>
                  <li>Building designs and brand identity</li>
                  <li>Connecting you to potential clients</li>
                  <li>Increasing sales</li>
                </div>
              </p>
            </div>
          </div>
        </div>
        <div class="card" data-index="0">
          <div class="card__inner">
            <div class="card__image-container">
              <img
                class="card__image"
                src="https://png.pngtree.com/background/20230524/original/pngtree-3-dimensional-cubes-inside-a-large-bright-orange-building-picture-image_2709821.jpg"
                // src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRWtzdS7AHvrQTRTLLlugR2XH-WW9YgdtX2xw&s"
                alt=""
              />
            </div>
            <div class="card__content">
              <h1 class="card__title">Services</h1>
              <p class="card__description">
                <div style={{}}>Choose the service you need.</div>
                <ServiceContainer>
                  <StyledMedium style={{ marginBottom: "12px" }}>
                    Popular Services to choose from
                  </StyledMedium>
                  <ServicesImageContainer>
                    {SERVICES?.map((service) => {
                      return (
                        <ServicesCategoryContainer
                          onClick={async () => {
                            navigate(
                              `category/products?parent_category_name=${service?.name}`
                            );
                          }}
                        >
                          <CardImageWrapper>
                            <img src={service.img} alt="service images" />
                          </CardImageWrapper>
                          <StyledSmall>{service.title}</StyledSmall>
                        </ServicesCategoryContainer>
                      );
                    })}
                  </ServicesImageContainer>
                </ServiceContainer>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="space"></div>
    </>
  );
};

export default OfferingCard;
