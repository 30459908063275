import React, { useEffect, useState } from "react";
import ThirdFold from "../ThirdFold/ThirdFold";
import FifthFold from "../FifthFold/FifthFold";
import ClientFirstFold from "../ClientsFirstFold/ClientFirstFold";
import axios from "axios";
import ClientSecondFold from "../ClientSecondFold/ClientSecondFold";
import ClientFourthFold from "../ClientFourthFold/ClientFourthFold";
import ContactFold from "../ContactFold/ContactFold";
import ClientFifthFold from "../ClientFifthFold/ClientFifthFold";
import ClientCategories from "../ClientCategories/ClientCategories";
import { useNavigate } from "react-router";
import Footer from "../Footer/Footer";
import { useDispatch } from "react-redux";
import types from "../../redux/types";

const ClientHomePage = ({ page_type }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = window.location.href.split("/");
  const [client, setClient] = useState({});
  const [errorPage, setErrorPage] = useState(false);
  const fetchClient = async () => {
    try {
      const clientResponse = await axios.get(
        `${process.env.REACT_APP_ORANGE_URL}/v1/client-page/${
          params[params?.length - 1]
        }`
      );
      if (clientResponse?.data?.code == "failed") {
        setErrorPage(true);
      } else {
        dispatch({
          type: types.SET_CLIENT_RESPONSE_SCHEMA,
          payload: clientResponse.data.data,
        });
        setClient(clientResponse.data.data);
      }
    } catch (error) {
      return;
    }
  };

  console.log("clientHomepage", client);
  useEffect(() => {
    fetchClient();
  }, []);

  if (errorPage) {
    return (
      <div>
        <h1>Oops! something went wrong</h1>
        <button onClick={() => navigate("/")}>back</button>
      </div>
    );
  } else if (client && Object.keys(client).length > 0) {
    return (
      <div>
        <ClientFirstFold client={client} page_type={page_type} />
        <ClientSecondFold page_type={page_type} client={client} />
        <ClientCategories client={client} />
        {/* <ThirdFold /> */}
        {/* <ServicesFold /> */}
        <ClientFourthFold client={client} />
        <ClientFifthFold client={client} />
        <ContactFold client={client} />
        <Footer />
      </div>
    );
  }
};

export default ClientHomePage;
