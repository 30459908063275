import React from "react";
import Navbar from "../Navbar/Navbar";
import ProductFirstFold from "../ProductFirstFold/ProductFirstFold";
import MegaMenu from "../Navbar/MegaMenu";
import Footer from "../Footer/Footer";
import { ClientProductWrapper } from "./ClientProductStyle";
import ClientNavbar from "../ClientNavbar/ClientNavbar";
import ClientProductFirstFold from "../ClientProductFirstFold/ClientProductFirstFold";

const ClientProduct = () => {
  return (
    <ClientProductWrapper>
      <ClientNavbar />
      <div style={{ position: "sticky", top: "100px", zIndex: 10 }}>
        <MegaMenu />
      </div>
      <ClientProductFirstFold />
      <div style={{ marginTop: "24px" }}>
        <Footer />
      </div>
    </ClientProductWrapper>
  );
};

export default ClientProduct;
