import React from "react";
import {
  DiscountCardImage,
  DiscountFooter,
  DiscountTitle,
  DiscountedCardWrapper,
  LeftDiscountCard,
  RightDiscountCard,
  RightImage,
} from "./DiscountedCardStyles";
import { Styled2XL, StyledXL } from "../../styles/CommonStyles";
import { screenWidth } from "../utilities";
import { useNavigate } from "react-router";

const DiscountedCard = ({ products, sequence, category }) => {
  const navigate = useNavigate();

  const screen = screenWidth();
  console.log("products101", products, category);

  if (screen == "mobile") {
    return;
  } else {
    return (
      <DiscountedCardWrapper>
        <LeftDiscountCard
          onClick={() =>
            navigate(
              `/category/products?parent_category_name=${category?.name}&category_id=${products?.[0]?.category_id}`
            )
          }
          style={{ order: sequence == "1" ? 1 : 2 }}
        >
          <DiscountCardImage>
            <DiscountTitle>
              <Styled2XL style={{ fontWeight: 900 }}>
                Flat <span>{products?.[0]?.discount_percentage}% Off</span>
              </Styled2XL>
            </DiscountTitle>
            <img src={products?.[0]?.media} alt={products?.[0]?.client_id} />
            <DiscountFooter>
              <Styled2XL style={{ fontWeight: 900, color: "white" }}>
                {`Super Discounts on ${products?.[0]?.name}`}
              </Styled2XL>
            </DiscountFooter>
          </DiscountCardImage>
        </LeftDiscountCard>
        <RightDiscountCard
          style={{
            order: sequence == "1" ? 2 : 1,
            marginLeft: sequence == "2" ? "32px" : null,
          }}
        >
          {products?.map((product, index) => {
            if (index != 1) {
              return (
                <>
                  <RightImage
                    key={product?.client_id}
                    onClick={() =>
                      navigate(
                        `/category/products?parent_category_name=${category.name}&category_id=${product?.category_id}`
                      )
                    }
                  >
                    <img src={product?.media} alt={product?.client_id} />
                    <StyledXL
                      style={{
                        position: "absolute",
                        bottom: "0",
                        borderRadius: "12px",
                        fontWeight: "900",
                        color: "white",
                        background: "black",
                        height: "20%",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: "12px",
                      }}
                    >
                      Flat {product?.discount_percentage}% Off
                    </StyledXL>
                  </RightImage>
                </>
              );
            }
          })}
        </RightDiscountCard>
      </DiscountedCardWrapper>
    );
  }
};

export default DiscountedCard;
