import React, { useState, useEffect } from "react";
import Card from "../Testimonials/Card";
import Testimonials from "../Testimonials/Testimonials";
import Footer from "../Footer/Footer";
import TestimonialCard from "../TestimonialCard/TestimonialCard";

const ClientFifthFold = ({ client }) => {
  const [testimonialSlides, setTestimonialSlides] = useState([]);

  const fetchCards = () => {
    let slides = client?.ui_schema.testimonials?.map((testimonial) => {
      const updatedTestimonialKeys = {
        ...testimonial,
        image: testimonial?.img,
      };
      return (
        <>
          <TestimonialCard testimonial={updatedTestimonialKeys} />
        </>
      );
    });
    setTestimonialSlides(slides);
  };

  useEffect(() => {
    fetchCards();
  }, []);

  return (
    <div>
      <Testimonials client={client} slides={testimonialSlides} />
    </div>
  );
};

export default ClientFifthFold;
