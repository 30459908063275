import React, { useEffect, useState } from "react";
import Navbar from "../Navbar/Navbar";
import {
  ImageContainer,
  ImageWrapper,
  SlideWrapper,
  VideoWrapper,
} from "../FirstFold/FirstFoldStyles";
import MegaMenu from "../Navbar/MegaMenu";
import Banner from "../Banner/Banner";
import { useSelector } from "react-redux";
import CustomSlider from "../common/CustomSlider";
import { BANNER_SLIDES } from "@/constants";
import ShopNowButton from "../common/ShopNowButton";
import { StyleButtonOnImage } from "../Banner/BannerStyles";
import { StyledXL } from "../../styles/CommonStyles";
import axios from "axios";
import { useDispatch } from "react-redux";
import types from "@/redux/types";
import { useNavigate } from "react-router";
import ClientNavbar from "../ClientNavbar/ClientNavbar";
import {
  ClientBackgroundWrapper,
  ClientBannerContainer,
  ClientImageContainer,
} from "./ClientFirstFoldStyles";

const ClientFirstFold = ({ page_type, client }) => {
  const [slides, setSlides] = useState([]);
  const [updatedBanner, setUpdatedBanner] = useState([]);
  const { client_brand_color } = useSelector((state) => state.HomepageReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const BANNER = [
    {
      image_url:
        "https://images.pexels.com/photos/1099816/pexels-photo-1099816.jpeg?auto=compress&cs=tinysrgb&w=800",
      title: "Buy from exclusive from our store",
    },
    {
      image_url:
        "https://images.pexels.com/photos/1099816/pexels-photo-1099816.jpeg?auto=compress&cs=tinysrgb&w=800",
      title: "Buy from exclusive from our store",
    },
    {
      image_url:
        "https://images.pexels.com/photos/1099816/pexels-photo-1099816.jpeg?auto=compress&cs=tinysrgb&w=800",
      title: "Buy from exclusive from our store",
    },
  ];

  useEffect(() => {
    // setUpdatedBanner((prev) => ({
    //   ...prev,
    //   bannerDetails: {
    //     banner_image: client?.banner_image,
    //     banner_title: client?.banner_title,
    //   },
    // }));
  }, []);

  const fetchHeroSlides = async () => {
    try {
      let slides = new Array(1).fill(10).map((slide, index) => {
        return (
          <SlideWrapper key={index}>
            <ClientImageContainer style={{ position: "relative" }}>
              <ImageWrapper
                src={client?.ui_schema?.banner_image}
              ></ImageWrapper>
              <StyleButtonOnImage>
                <StyledXL style={{ color: "white" }}>
                  {client?.ui_schema?.brand_tagline}
                </StyledXL>
                <ShopNowButton
                  text={client?.ui_schema?.button_action_name}
                  arrow={true}
                  onClick={() => {
                    dispatch({ type: types.SET_CLIENT_DATA, payload: slide });
                  }}
                />
              </StyleButtonOnImage>
            </ClientImageContainer>
          </SlideWrapper>
        );
      });
      setSlides(slides);
    } catch (error) {
      return;
    }
  };

  console.log("slides", slides);

  useEffect(() => {
    fetchHeroSlides();
  }, []);

  return (
    <ClientBackgroundWrapper
      style={{ background: client_brand_color?.primary }}
    >
      <ClientNavbar page_type={page_type} client={client} />
      <div style={{ position: "sticky", top: "100px", zIndex: 10 }}>
        <MegaMenu />
      </div>

      <CustomSlider slides={slides} landingFold="first" />
    </ClientBackgroundWrapper>
  );
};

export default ClientFirstFold;
