import styled from "styled-components";

export const FilterWrapper = styled.div`
  margin: 0px 32px 0px;
  padding: 72px 32px 0px 0px;
  border-right: 1px solid #f5f5f5;
  position: sticky;
  top: 60px;
  height: 900px;
  overflow: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
  @media screen and (min-width: 1500px) {
    width: 244px;
  }

  @media screen and (min-width: 1200px) {
    width: 260px;
  }
`;

export const ListHeader = styled.div`
  margin-bottom: 18px;
`;

export const AvailableFilterContainer = styled.div`
  margin-bottom: 6px;
  position: relative;
  top: 6px;
`;

export const FilterContent = styled.div`
  margin-bottom: 12px;
`;

export const FilterHeading = styled.div`
  display: flex;
  gap: 54px;
`;

export const Filters = styled.div`
  margin-top: 32px;
`;
