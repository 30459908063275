import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Product from "./components/Product/Product";
import Cart from "./components/Cart/Cart";
import ClientHomePage from "./components/ClientHomePage/ClientHomePage";
import ClientProduct from "./components/ClientProduct/ClientProduct";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/category/products" element={<Product />} />
        <Route path="/:client_name" element={<ClientHomePage />} />
        <Route
          path="/:client_name/category/products"
          element={<ClientProduct />}
        />
        <Route
          path="/:client_name/category/:category/cart/:product-name"
          element={<Cart />}
        />
        <Route path="/:client_name/cart/:product-name" element={<Cart />} />
        <Route path="/cart/:product-id" element={<Cart />} />
        <Route path="/doctors" element={<Home page_type="services" />} />
      </Routes>
    </Router>
  );
}

export default App;
