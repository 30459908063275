import { NAV_CONTENT } from "../../constants";
import types from "../types";

const INITIAL_STATE = {
  megamenu_content: {
    menu: NAV_CONTENT?.[0]?.products,
    showMenu: false,
  },
  client_data: {},
  categoryIdAndNames: {},
  client_brand_color: {},
  selectedProduct: {},
  client_response_schema: {},
};

export const HomepageReducer = (state = INITIAL_STATE, action) => {
  if (action.type === types.SET_NAV_CONTENT) {
    return { ...state, megamenu_content: action.payload };
  }

  if (action.type === types.SET_SINGLE_PRODUCT_DETAILS) {
    return { ...state, ...action.payload };
  }
  if (action.type === types.SET_CLIENT_DATA) {
    return { ...state, client_data: action.payload };
  }
  if (action.type === types.SET_CLIENT_BRAND_COLOR) {
    return { ...state, client_brand_color: action.payload };
  }
  if (action.type === types.SET_PRODUCT_DATA_AFTER_NAVIGATION) {
    return { ...state, selectedProduct: action.payload };
  }
  if (action.type === types.SET_CLIENT_RESPONSE_SCHEMA) {
    return { ...state, client_response_schema: action.payload };
  }
  if (action.type === types.SET_CATEGORY) {
    return {
      ...state,
      categoryIdAndNames: action.payload,
    };
  }
  return { ...state };
};
