import styled from "styled-components";

export const ClientOfferingsWrapper = styled.div`
  height: 400px;
  width: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 24px;
`;
