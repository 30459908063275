import React, { useEffect, useState } from "react";
import Navbar from "../Navbar/Navbar";
import {
  FirstWrapper,
  ImageContainer,
  ImageWrapper,
  SlideWrapper,
  VideoWrapper,
} from "./FirstFoldStyles";
import MegaMenu from "../Navbar/MegaMenu";
import Banner from "../Banner/Banner";
import CustomSlider from "../common/CustomSlider";
import ShopNowButton from "../common/ShopNowButton";
import { StyleButtonOnImage } from "../Banner/BannerStyles";
import { StyledXL } from "../../styles/CommonStyles";
import axios from "axios";
import { useDispatch } from "react-redux";
import types from "@/redux/types";
import { useNavigate } from "react-router";
import { screenWidth } from "../utilities";

const FirstFold = ({ page_type }) => {
  const [slides, setSlides] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const screen = screenWidth();

  const settings = {
    autoPlay: true,
    swipeable: true,
    arrow: true,
    draggable: true,
    showDots: false,
    infinite: true,
    partialVisible: false,
    onHover: true,
  };

  const fetchHeroSlides = async () => {
    try {
      const bannerData = await axios.get(
        `${process.env.REACT_APP_ORANGE_URL}/v1/home/banner`
      );

      let slides = bannerData?.data?.data?.map((slide, index) => {
        return (
          <SlideWrapper key={slide?.id}>
            <VideoWrapper>
              <iframe
                className="iframe"
                style={{
                  margin: "0",
                  position: "relative",
                  bottom: "10px",
                  zIndex: 1,
                }}
                width={screen == "mobile" ? "120%" : "180%"}
                height="200%"
                srcdoc={`<video autoplay loop muted playsinline width="150%"
                height="200%">
              <source src= ${
                process.env.REACT_APP_ORANGE_URL + "/" + slide.banner_video_path
              } type='video/mp4'>
              Your browser does not support the video tag.
          </video>`}
                frameborder="0"
              ></iframe>
            </VideoWrapper>
            <ImageContainer style={{ position: "relative" }}>
              <ImageWrapper
                src={`${process.env.REACT_APP_ORANGE_URL}/${slide?.banner_image_path}`}
              ></ImageWrapper>
              <StyleButtonOnImage>
                <StyledXL style={{ color: "white" }}>{slide?.title}</StyledXL>
                <ShopNowButton
                  text="Shop Now"
                  arrow={true}
                  onClick={() => {
                    dispatch({ type: types.SET_CLIENT_DATA, payload: slide });
                    // Render client page
                    // navigate(`/${slide?.client?.client_code}`);
                    navigate(`/${slide?.client?.client_code}`);
                  }}
                />
              </StyleButtonOnImage>
            </ImageContainer>
          </SlideWrapper>
        );
      });
      setSlides(slides);
    } catch (error) {
      return;
    }
  };

  console.log("slides", slides);

  useEffect(() => {
    fetchHeroSlides();
  }, []);

  return (
    <>
      <Navbar page_type={page_type} />
      <div style={{ position: "sticky", top: "100px", zIndex: 10 }}>
        <MegaMenu />
      </div>

      <Banner />
      {slides ? (
        <CustomSlider settings={settings} slides={slides} landingFold="first" />
      ) : null}
    </>
  );
};

export default FirstFold;
