import React from "react";
import {
  ProductCardWrapper,
  ProductImage,
  ProductListings,
} from "./ProductCardStyles";
import { StyledMedium } from "@/styles/CommonStyles";
import ShopNowButton from "../ShopNowButton";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";

const ProductCard = ({ product, parentCategory }) => {
  // const [results, setResults] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <ProductCardWrapper>
      <ProductImage>
        <img src={product?.icon_url} alt="product-image" />
      </ProductImage>
      <ProductListings>
        <StyledMedium style={{ fontWeight: "900" }}>
          {product?.offer}
        </StyledMedium>
        <StyledMedium className="padding" style={{ fontWeight: "900" }}>
          {product?.display_name}
        </StyledMedium>

        <StyledMedium style={{ fontWeight: "900" }}>
          {product?.pricing}
        </StyledMedium>
        <ShopNowButton
          text="Shop Now"
          onClick={() =>
            navigate(
              `/category/products?parent_category_name=${parentCategory?.name}&category_id=${product?.id}`
            )
          }
        />
      </ProductListings>
    </ProductCardWrapper>
  );
};

export default ProductCard;
