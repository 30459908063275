import React from "react";

// Inline CSS styles
const footerStyle = {
  backgroundColor: "black",
  color: "white",
  padding: "20px 0",
  textAlign: "center",
};

const linkStyle = {
  color: "white",
  textDecoration: "none",
  margin: "0 15px",
};

const Footer = () => {
  return (
    <footer style={footerStyle}>
      <nav style={{}}>
        <a href="/" style={linkStyle}>
          Home
        </a>

        <a href="/about" style={linkStyle}>
          About
        </a>
        <a href="#services" style={linkStyle}>
          Services
        </a>
        <a href="#contact" style={linkStyle}>
          Contact
        </a>
      </nav>
      <p style={{ marginTop: "10px" }}>© 2024 Orange</p>
    </footer>
  );
};

export default Footer;
