import React, { useState, useEffect } from "react";
import {
  AvailableFilterContainer,
  FilterContent,
  FilterHeading,
  FilterWrapper,
  Filters,
  ListHeader,
} from "./ClientFilterStyles";
import { FILTERS_CONTENT } from "../../constants";
import { StyledMedium, StyledSmall } from "../../styles/CommonStyles";
import { useSearchParams } from "react-router-dom";

import axios from "axios";

const ClientFilter = () => {
  const [filtererData, setFilteredData] = useState([]);
  const [checked, setChecked] = useState({});
  const [searchParams] = useSearchParams();
  const parentName = searchParams.get("parent_category_name");
  const category_id = searchParams.get("category_id");

  const fetchProductCategories = async () => {
    try {
      let result = [];
      if (parentName && category_id) {
        result = await axios.get(
          `${process.env.REACT_APP_ORANGE_URL}/v1/search/product/filters?parent_category_name=${parentName}&category_id=${category_id}`
        );
      } else if (parentName) {
        result = await axios.get(
          `${process.env.REACT_APP_ORANGE_URL}/v1/search/product/filters?parent_category_name=${parentName}`
        );
      } else if (category_id) {
        result = await axios.get(
          `${process.env.REACT_APP_ORANGE_URL}/v1/search/product/filters?category_id=${category_id}`
        );
      } else {
        result = await axios.get(
          `${process.env.REACT_APP_ORANGE_URL}/v1/search/product/filters`
        );
      }
      // const result = await axios.get(
      //   `${process.env.REACT_APP_ORANGE_URL}/v1/search/product/filters`
      // );
      setFilteredData(result?.data?.data);
    } catch (error) {
      return;
    }
  };

  useEffect(() => {
    fetchProductCategories();
  }, []);

  console.log("filteredData101", filtererData);
  return (
    <FilterWrapper>
      <FilterHeading>
        <StyledMedium style={{ cursor: "pointer" }}>Filters</StyledMedium>
        <StyledMedium style={{ cursor: "pointer" }}>Clear All</StyledMedium>
      </FilterHeading>
      <Filters>
        {Object?.entries(filtererData)?.map(([key, value], index) => {
          return (
            <FilterContent>
              <ListHeader>{key}</ListHeader>
              {value?.map((available_filter) => {
                console.log("typeOF", typeof available_filter);
                return (
                  <div>
                    <div class="checkboxes__row">
                      <div class="checkboxes__item">
                        <label class="checkbox style-c">
                          <input
                            type="checkbox"
                            onClick={(e) =>
                              setChecked((prev) => ({
                                ...prev,
                                [key]:
                                  typeof available_filter == "object"
                                    ? available_filter?.display_name
                                    : available_filter,
                              }))
                            }
                          />
                          <div class="checkbox__checkmark"></div>
                          <AvailableFilterContainer>
                            <StyledSmall>
                              {typeof available_filter !== "object"
                                ? available_filter
                                : available_filter?.display_name}
                            </StyledSmall>
                          </AvailableFilterContainer>
                        </label>
                      </div>
                    </div>
                  </div>
                );
              })}
              <br />
              <br />
            </FilterContent>
          );
        })}
      </Filters>
    </FilterWrapper>
  );
};

export default ClientFilter;
