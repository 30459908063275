import React, { useEffect, useState } from "react";
import {
  Box,
  ContactDetails,
  ClientCategoriesWrapper,
  Categories,
  CategoryParent,
} from "./ClientCategoriesStyles";
import { Styled2XL, StyledMedium, StyledXL } from "../../styles/CommonStyles";
import axios from "axios";
import { useNavigate } from "react-router";

const ClientCategories = ({ client }) => {
  const [category, setCategory] = useState();
  const navigate = useNavigate();
  const fetchBasedOnServiceType = async () => {
    try {
      if (client?.ui_schema?.service_type == "listing") {
        // const result = await axios.get(
        //   `${process.env.REACT_APP_ORANGE_URL}/v1/home/categories?client_id=${client?.id}`
        // );
        // setCategory(result?.data?.data);
        setCategory(client?.ui_schema?.services);
      }
    } catch (error) {
      return;
    }
  };

  useEffect(() => {
    fetchBasedOnServiceType();
  }, []);

  return (
    <ClientCategoriesWrapper>
      <Styled2XL style={{ color: "white" }}>
        {client?.ui_schema?.service_type == "product"
          ? "Categories"
          : "Services"}
      </Styled2XL>
      <CategoryParent>
        {category?.map((data, index) => {
          return (
            <Categories
              onClick={() =>
                navigate(
                  `/${client?.client_code}/category/products?parent_category_name=${data?.name}`
                )
              }
            >
              <img src={data?.image} alt={index} />
              <StyledXL style={{ color: "white", marginTop: "12px" }}>
                {data?.title}
              </StyledXL>
            </Categories>
          );
        })}
      </CategoryParent>
    </ClientCategoriesWrapper>
  );
};

export default ClientCategories;
