import React from "react";
import {
  CartMultiImageWrapper,
  CartProductWrapper,
  CartSideImageContainer,
  Decription,
  InfoContainer,
  MainImageContainer,
  MoreCartProducts,
  MultipleImagesContainer,
  ProductDescriptionContainer,
  RangerVerifiedContainer,
  SellerInformationContainer,
  StarContainer,
  SuggestPrice,
  SuggestedImage,
  SuggestedName,
  SuggestedProductContainer,
  Title,
  VerifiedContainer,
} from "./CartProductStyles";
import { StyledMedium, StyledSmall, StyledXL } from "../../styles/CommonStyles";
import ShopNowButton from "../common/ShopNowButton";
import orange_verified from "@/assets/orange_verified.png";
import { IoIosStar } from "react-icons/io";
import { FaWhatsapp } from "react-icons/fa6";

const CartProduct = ({ cartList }) => {
  const { product, client, suggested_products } = cartList;
  return (
    <>
      <CartProductWrapper
        style={{
          justifyContent: "initial",
        }}
      >
        {Object.entries(cartList?.product)?.map(([key, product], index) => {
          if (key == "media") {
            return (
              <MultipleImagesContainer>
                <CartSideImageContainer>
                  {product?.map((item, i) => {
                    return (
                      <CartMultiImageWrapper
                        key={index}
                        style={{
                          margin: 0,
                        }}
                      >
                        <img src={item.path} alt="image" />
                      </CartMultiImageWrapper>
                    );
                  })}
                </CartSideImageContainer>
              </MultipleImagesContainer>
            );
          }
        })}
        <div>
          <MainImageContainer
            style={{
              width: "400px",
            }}
          >
            <img src={cartList?.product?.media[0]?.path} />
          </MainImageContainer>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            <ShopNowButton
              text="View Number"
              arrow={false}
              icon={<FaWhatsapp />}
            />
            <ShopNowButton text="Buy Now" arrow={false} icon={<FaWhatsapp />} />
          </div>
        </div>

        <ProductDescriptionContainer>
          <Decription>
            <StyledMedium>{product?.code}</StyledMedium>
            <StyledSmall style={{ marginTop: "12px", letterSpacing: "1" }}>
              {product?.description}
            </StyledSmall>
            <StyledSmall
              style={{
                marginTop: "12px",
                letterSpacing: "1",
                textDecoration: "line-through",
              }}
            >
              &#8377;{product?.price}
            </StyledSmall>
            <StyledMedium
              style={{
                marginTop: "12px",
                letterSpacing: "1",
              }}
            >
              &#8377;{product?.discounted_price}
            </StyledMedium>
          </Decription>

          <RangerVerifiedContainer>
            <VerifiedContainer>
              <img src={orange_verified} />
            </VerifiedContainer>
            Orange verified partner
          </RangerVerifiedContainer>

          <SellerInformationContainer>
            <InfoContainer>
              <StyledMedium>Company Name:</StyledMedium>
              <StyledMedium>{client?.company_name}</StyledMedium>
            </InfoContainer>
            <InfoContainer>
              <StyledMedium>Partner Ratings:</StyledMedium>
              <StyledMedium>
                <StarContainer>
                  {new Array(Math.floor(client?.rating))
                    .fill(10)
                    ?.map((item) => {
                      return (
                        <div>
                          <IoIosStar />
                        </div>
                      );
                    })}
                </StarContainer>
              </StyledMedium>
            </InfoContainer>
            <InfoContainer>
              <StyledMedium>Partner's Address:</StyledMedium>
              <StyledMedium>{client?.address}</StyledMedium>
            </InfoContainer>
            <InfoContainer className="style-explore-btn">
              <ShopNowButton
                text={`Explore ${client?.company_name}`}
                arrow={true}
                icon={<FaWhatsapp />}
              />
            </InfoContainer>
          </SellerInformationContainer>
        </ProductDescriptionContainer>
      </CartProductWrapper>
      <div style={{ borderTop: "1px solid grey" }}>
        <Title>
          <StyledXL>View more products by the partner</StyledXL>
        </Title>
        <MoreCartProducts>
          {suggested_products?.map((list, index) => {
            if (index < 6)
              return (
                <SuggestedProductContainer>
                  <SuggestedImage>
                    <img src={list?.media[0]?.path} alt="listed_image" />
                  </SuggestedImage>
                  <SuggestedName>{list.name}</SuggestedName>
                  <SuggestPrice> &#8377;{list.price}</SuggestPrice>
                  <SuggestPrice>
                    {" "}
                    Stock Available : {list.stock_quantity}
                  </SuggestPrice>
                </SuggestedProductContainer>
              );
          })}
          ;
        </MoreCartProducts>
      </div>
    </>
  );
};

export default CartProduct;
