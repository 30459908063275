import styled from "styled-components";

export const ContactFoldWrapper = styled.div`
  background: black;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
`;

export const ContactDetails = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  border: 1px solid grey;
  margin-top: 40px;
`;

export const Box = styled.div`
  padding: 100px 0px;

  &:nth-child(1) {
    width: 460px;
    border-right: 1px solid grey;
  }
  &:nth-child(2) {
    width: 300px;
    border-right: 1px solid grey;
  }
`;
