import React from "react";
import "./ContinuousLines.css";
import { StyledMedium } from "../../styles/CommonStyles";
import { useSelector } from "react-redux";

const ContinuousTextLine = ({ content }) => {
  const { client_brand_color } = useSelector((state) => state.HomepageReducer);

  return (
    <div
      className="scroll-container"
      style={{
        backgroundColor: client_brand_color?.primary,
      }}
    >
      <div className="scroll-text">
        <StyledMedium
          style={{ fontWeight: 900, color: client_brand_color?.tertiary }}
        >
          {content}
        </StyledMedium>
      </div>
    </div>
  );
};

export default ContinuousTextLine;
