import React, { useState, useEffect } from "react";
import {
  AvailableFilterContainer,
  FilterContent,
  FilterHeading,
  FilterWrapper,
  Filters,
  ListHeader,
} from "./FilterStyles";
import { StyledMedium, StyledSmall } from "../../styles/CommonStyles";
import axios from "axios";
import { useSearchParams } from "react-router-dom";

const Filter = () => {
  const [filtererData, setFilteredData] = useState([]);
  const [checked, setChecked] = useState({});
  const [searchParams] = useSearchParams();
  const parentName = searchParams.get("parent_category_name");
  const category_id = searchParams.get("category_id");

  const fetchProductCategories = async () => {
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_ORANGE_URL}/v1/search/filters?category_id=${
          category_id ?? ""
        }&parent_category_name=${parentName ?? ""}`
      );
      console.log("result101", result);
      setFilteredData(result?.data?.data);
    } catch (error) {
      return;
      // return <FallbackUI />;
    }
  };

  useEffect(() => {
    fetchProductCategories();
  }, [parentName, category_id]);

  if (filtererData) {
    return (
      <FilterWrapper>
        <FilterHeading>
          <StyledMedium style={{ cursor: "pointer" }}>Filters</StyledMedium>
          <StyledMedium style={{ cursor: "pointer" }}>Clear All</StyledMedium>
        </FilterHeading>
        {Object?.entries(filtererData)?.length > 0 ? (
          <Filters>
            {Object?.entries(filtererData)?.map(([key, value], index) => {
              return (
                <FilterContent>
                  <ListHeader>{key}</ListHeader>
                  {value?.map((available_filter) => {
                    return (
                      <div>
                        <div class="checkboxes__row">
                          <div class="checkboxes__item">
                            <label class="checkbox style-c">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  setChecked((prev) => {
                                    if (key == "category") {
                                      return {
                                        ...prev,
                                        [key]: {
                                          [available_filter?.display_name]:
                                            e.target.checked,
                                        },
                                      };
                                    } else {
                                      return {
                                        ...prev,
                                        [key]: {
                                          [available_filter]: e.target.checked,
                                        },
                                      };
                                    }
                                  });
                                }}
                                checked={checked?.[key]?.[available_filter]}
                              />
                              <div class="checkbox__checkmark"></div>
                              <AvailableFilterContainer>
                                <StyledSmall>
                                  {typeof available_filter !== "object"
                                    ? available_filter
                                    : available_filter?.display_name}
                                </StyledSmall>
                              </AvailableFilterContainer>
                            </label>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <br />
                  <br />
                </FilterContent>
              );
            })}
          </Filters>
        ) : null}
      </FilterWrapper>
    );
  }
};

export default Filter;
