import React from "react";
import { PhotoBannerWrapper } from "./PhotoBannerStyles";

const PhotoBanner = ({ bannerImage }) => {
  return (
    <PhotoBannerWrapper>
      <img src={bannerImage} alt="banner image" />
    </PhotoBannerWrapper>
  );
};

export default PhotoBanner;
