import React from "react";
import { BannerWrapper, ContentWrapper } from "./BannerStyles";
import { StyledSmall } from "../../styles/CommonStyles";
import { GoCheckCircle } from "react-icons/go";
import { screenWidth } from "../utilities";

const Banner = () => {
  const screen = screenWidth();
  return (
    <BannerWrapper>
      {screen != "mobile" ? (
        <ContentWrapper>
          <GoCheckCircle size={18} color="green" />
          <StyledSmall>
            Assured quality products at best prices
            <strong> directly from Wholesellers.</strong>
          </StyledSmall>
        </ContentWrapper>
      ) : null}

      <ContentWrapper>
        <GoCheckCircle size={18} color="green" />
        <StyledSmall>100% Orange Verified partners.</StyledSmall>
      </ContentWrapper>

      {screen != "mobile" ? (
        <ContentWrapper>
          <GoCheckCircle size={18} color="green" />
          <StyledSmall>
            Find the service providers at your convenience.
          </StyledSmall>
        </ContentWrapper>
      ) : null}
    </BannerWrapper>
  );
};

export default Banner;
