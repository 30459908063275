import React from "react";
import CustomSlider from "../common/CustomSlider";
import { TestimonialWrapper } from "./TestimonialStyles";

const Testimonials = ({ slides }) => {
  const settings = {
    autoPlay: true,
    swipeable: true,
    draggable: true,
    showDots: false,
    infinite: true,
    partialVisible: true,
    onHover: true,
  };

  return (
    <TestimonialWrapper>
      <CustomSlider slides={slides} landingFold="" settings={settings} />
    </TestimonialWrapper>
  );
};

export default Testimonials;
