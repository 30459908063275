import React from "react";
import {
  CardTitle,
  StyledCard,
  StyledMedium,
  StyledXL,
} from "../../styles/CommonStyles";
import appliances from "@/assets/appliances.jpg";

const Card = ({ title, subtitle, image }) => {
  return (
    <StyledCard image={appliances}>
      <CardTitle>
        <StyledXL style={{ color: "white", fontWeight: "900" }}>
          {title}
        </StyledXL>
      </CardTitle>
    </StyledCard>
  );
};

export default Card;
