import React from "react";
import { CiBoxes } from "react-icons/ci";
import { GiOrange } from "react-icons/gi";
import { ClientOfferingsWrapper } from "./ClientOfferingsStyles";
import { screenWidth } from "../utilities";
import { Styled2XL, StyledMedium, StyledXL } from "../../styles/CommonStyles";

const ClientOfferings = ({ client }) => {
  const screen = screenWidth();
  const OFFERINGS = [
    {
      icon: <CiBoxes />,
      title: "Buy from biggest wholesellers",
    },
    {
      icon: <GiOrange />,
      title:
        "Buy anything and everything. (Dogs and Cats, Automobile, Home Decor, Clothing...)",
    },
    {
      icon: <GiOrange />,
      title: "Deals in Corporate Gifting and Bulk Orders",
    },
    {
      icon: <GiOrange />,
      title: "Boost your business with our platform.",
    },
  ];

  return (
    <ClientOfferingsWrapper>
      <Styled2XL>{client?.company_name}</Styled2XL>
      <StyledXL>{client.description}</StyledXL>
    </ClientOfferingsWrapper>
  );
};

export default ClientOfferings;
