import styled from "styled-components";

export const ClientBannerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 44px 0px 24px;
  img {
    border-radius: 24px;
    width: 80%;
    height: 600px;
  }
`;

export const ClientBackgroundWrapper = styled.div``;

export const ClientImageContainer = styled.div`
  height: 80vh;
  width: 100%;
  position: relative;
  z-index: 1;

  @media (max-width: 768px) {
    height: 40vh;
    width: 100vw;
    left: 0;
    margin-top: 100px;
  }
`;
