import React from "react";
import Navbar from "../Navbar/Navbar";
import { ProductWrapper } from "./ProductStyles";
import ProductFirstFold from "../ProductFirstFold/ProductFirstFold";
import MegaMenu from "../Navbar/MegaMenu";
import Footer from "../Footer/Footer";

const Product = () => {
  return (
    <ProductWrapper>
      <Navbar />
      <div style={{ position: "sticky", top: "100px", zIndex: 10 }}>
        <MegaMenu />
      </div>
      <ProductFirstFold />
      <div style={{ marginTop: "24px" }}>
        <Footer />
      </div>
    </ProductWrapper>
  );
};

export default Product;
