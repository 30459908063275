import React from "react";
import {
  FourthFoldWrapper,
  StyledBanner,
} from "../FourthFold/FourthFoldStyles";
import ContinuousTextLine from "../ContinuousTextLine/ContinuousTextLine";
import HomepageProductListing from "../HomepageProductListing/HomepageProductListing";
import {
  DISCOUNTED_KITCHEN_APPLIANCES,
  KITCHEN_APPLIANCES,
} from "../../constants";
import DiscountedCard from "../DiscountedCard/DiscountedCard";
import PhotoBanner from "../PhotoBanner/PhotoBanner";
import petshop from "@/assets/pet_shop.jpg";

const ServicesFold = () => {
  return (
    <FourthFoldWrapper>
      {/* <HomepageProductListing products={CLOTHING} header="Clothing" />
  <DiscountedCard products={DISCOUNTED_CLOTHING} sequence="1" /> */}
      <ContinuousTextLine content="At Orange you will find all products and services under one roof. From premium to affordable we have it all. Slay in style, get services on the doorstep. Orange has partnered with range of service providers in sectors like healthcare, farms, electrician, plumber, wholesellers and much more." />
      <StyledBanner>
        <PhotoBanner bannerImage={petshop} />
      </StyledBanner>
      <HomepageProductListing
        products={KITCHEN_APPLIANCES}
        header="Kitchen Appliances"
      />
      <DiscountedCard products={DISCOUNTED_KITCHEN_APPLIANCES} sequence="1" />
    </FourthFoldWrapper>
  );
};

export default ServicesFold;
