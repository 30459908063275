import React, { useState, useEffect } from "react";
import { SHOW_LIST } from "../../constants";
import {
  BuyButtonContainer,
  BuyNowButton,
  ListingCard,
  ListingContainer,
  QuantityButton,
  QuantityContainer,
  ShopNowContainer,
} from "./ClientProductListingStyles";
import { StyledMedium, StyledSmall } from "@/styles/CommonStyles";
import { LuMinus } from "react-icons/lu";
import { FaPlus } from "react-icons/fa6";
import { IoMdHeartEmpty } from "react-icons/io";
import { IoCartOutline } from "react-icons/io5";
import { FaWhatsapp } from "react-icons/fa";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import types from "@/redux/types";
import Pagination from "../Pagination/Pagination";
import axios from "axios";
import { useSearchParams } from "react-router-dom";

const ClientProductListing = ({ client }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const parentName = searchParams.get("parent_category_name");
  const category_id = searchParams.get("category_id");
  const urlParams = window.location.pathname.split("/")?.[1];
  const [productList, setProductList] = useState([]);
  const [filtererData, setFilteredData] = useState([]);

  const page_type = localStorage.getItem("page_type");

  const fetchProductByCategoriesFilter = async () => {
    try {
      let result = [];
      if (parentName && category_id) {
        result = await axios.get(
          `${process.env.REACT_APP_ORANGE_URL}/v1/search/products?parent_category_name=${parentName}&category_id=${category_id}`
        );
      } else if (parentName) {
        result = await axios.get(
          `${process.env.REACT_APP_ORANGE_URL}/v1/search/products?parent_category_name=${parentName}`
        );
      } else if (category_id) {
        result = await axios.get(
          `${process.env.REACT_APP_ORANGE_URL}/v1/search/products?category_id=${category_id}`
        );
      } else {
        result = await axios.get(
          `${process.env.REACT_APP_ORANGE_URL}/v1/search/products`
        );
      }

      setProductList(result?.data?.data);
    } catch (error) {
      return;
    }
  };

  useEffect(() => {
    fetchProductByCategoriesFilter();
  }, []);

  const params = window.location.pathname.split(0);
  const companyName = window.location.pathname.split("/")?.[1];

  console.log("params", companyName);
  return (
    <ListingContainer>
      {productList?.map((product) => {
        return (
          <div>
            <ListingCard
              width={urlParams == "automobile" ? "274px" : "156px"}
              className="img-area single-img"
            >
              <img
                src={product?.Media?.[0]?.path}
                alt={product?.name}
                className="image"
              />
              <div class="img-overlay">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "6px 12px",
                  }}
                >
                  <StyledSmall>
                    <span style={{ color: "white" }}>In stock</span>
                  </StyledSmall>
                  <StyledSmall style={{ color: "white" }}>
                    {product?.quantity}
                  </StyledSmall>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "0px 12px",
                    position: "relative",
                    top: "14px",
                  }}
                >
                  <BuyNowButton
                    onClick={() => {
                      dispatch({
                        type: types.SET_SINGLE_PRODUCT_DETAILS,
                        payload: { product_details: product },
                      });
                      if (client?.ui_schema?.service_type == "listing") {
                        navigate(`/${client?.client_code}`);
                      } else {
                        navigate(`${params}/cart/${product?.code}`);
                      }
                    }}
                  >
                    More
                  </BuyNowButton>
                </div>
              </div>
            </ListingCard>
            <div style={{ margin: "6px 0" }}>
              <StyledSmall>{product?.name}</StyledSmall>
            </div>

            {page_type === "listing" ? (
              <StyledSmall style={{ marginBottom: "16px" }}>
                {product?.description}
              </StyledSmall>
            ) : (
              <div style={{ display: "flex", gap: "8px" }}>
                <StyledMedium
                  style={{
                    marginBottom: "6px",
                    textDecoration: "line-through",
                    opacity: "0.7",
                  }}
                >
                  &#8377; {product?.price}
                </StyledMedium>
                <StyledMedium style={{ marginBottom: "6px" }}>
                  &#8377; {product?.discounted_price}
                </StyledMedium>
              </div>
            )}

            <ShopNowContainer>
              <QuantityContainer>
                <QuantityButton>
                  <FaWhatsapp
                    size={20}
                    color="green"
                    onClick={() =>
                      window.open(
                        `https://wa.me/${client?.whatsapp_number}?text=${client?.default_message_from_client}`,
                        "__blank"
                      )
                    }
                  />
                </QuantityButton>
              </QuantityContainer>
              <BuyButtonContainer>
                <BuyNowButton>View Number</BuyNowButton>
              </BuyButtonContainer>
            </ShopNowContainer>
          </div>
        );
      })}
      {/* <Pagination items={productList} itemsPerPage={5} />; */}
    </ListingContainer>
  );
};

export default ClientProductListing;
