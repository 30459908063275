import styled from "styled-components";

export const ClientCategoriesWrapper = styled.div`
  background: black;
  height: 80vh;
  display: flex;
  padding-top: 44px;
  align-items: center;
  width: 100%;
  flex-direction: column;
`;

export const Categories = styled.div`
  height: 298px;
  width: 298px;

  img {
    height: 100%;
    width: 100%;
    border-radius: 12px;
  }
  transition: all 0.3s ease-in-out;
  &:hover {
    transform: scale(1.1);
    box-shadow: 0 0 15px #d35400;
    border-radius: 12px;
    transition: all 0.3s ease-in-out;
  }
`;

export const CategoryParent = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 64px;
  margin-top: 40px;
  flex-wrap: wrap;
`;
