import React, { useEffect, useState } from "react";
import HomepageProductListing from "../HomepageProductListing/HomepageProductListing";
import {
  AUTOMOBILE,
  CLOTHING,
  DISCOUNTED_CLOTHING,
  DISCOUNTED_KITCHEN_APPLIANCES,
  DISCOUNTED_PET_SHOP,
  KITCHEN_APPLIANCES,
  PET_SHOP,
} from "../../constants";
import DiscountedCard from "../DiscountedCard/DiscountedCard";
import ContinuousTextLine from "../ContinuousTextLine/ContinuousTextLine";
import PhotoBanner from "../PhotoBanner/PhotoBanner";
import petshop from "@/assets/pet_shop.jpg";
import axios from "axios";
import { useSelector } from "react-redux";
import { ClientFourthFoldWrapper } from "./ClientFourthFoldStyles";

const ClientFourthFold = ({ client }) => {
  const [results, setResults] = useState({});

  const fetchProducts = async () => {
    try {
      const categoriesProduct = await axios.get(
        `${process.env.REACT_APP_ORANGE_URL}/v1/home/category-section?client_id=${client.id}`
      );
      setResults(categoriesProduct?.data?.data);
    } catch (error) {
      return;
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  if (results && results.length > 0) {
    return results?.map((item) => {
      return (
        <ClientFourthFoldWrapper>
          <ContinuousTextLine content="At Orange you will find all products and services under one roof. From premium to affordable we have it all. Slay in style, get services on the doorstep. Orange has partnered with range of service providers in sectors like healthcare, farms, electrician, plumber, wholesellers and much more." />
          <HomepageProductListing
            products={item}
            header={item?.category?.name}
          />
        </ClientFourthFoldWrapper>
      );
    });
  }
};

export default ClientFourthFold;
