import styled from "styled-components";

export const PhotoBannerWrapper = styled.div`
  height: 760px;
  width: 100%;

  img {
    height: 100%;
    width: 100%;
  }
`;
