import React, { useState } from "react";
import {
  DescriptionContainer,
  ImgWrapper,
  TestimonialWrapper,
  TextContainer,
} from "./TestimonialCardStyles";
import { StyledMedium, StyledSmall, StyledXL } from "../../styles/CommonStyles";

const TestimonialCard = ({ testimonial }) => {
  console.log("testimonial101", testimonial);
  return (
    <TestimonialWrapper>
      <ImgWrapper>
        <img src={testimonial?.image} />
      </ImgWrapper>
      <TextContainer>
        <StyledXL>{testimonial?.name}</StyledXL>
        <DescriptionContainer>
          <StyledSmall>{testimonial?.description}</StyledSmall>
        </DescriptionContainer>
      </TextContainer>
    </TestimonialWrapper>
  );
};

export default TestimonialCard;
