import styled from "styled-components";

export const WhatWeOfferWrapper = styled.div``;

export const OfferingsHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 768px) {
    padding: 0px 24px;
    text-align: center;
  }
`;

export const CardImageWrapper = styled.div`
  height: 100px;
  width: 100px;
  img {
    height: 100%;
    width: 100%;
    border-radius: 12px;
  }
`;

export const ServicesImageContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: start;
  width: 100%;
  gap: 14px;
  flex-wrap: wrap;
  @media only screen and (max-width: 768px) {
    flex-wrap: nowrap;
    overflow-x: scroll;
    scrollbar-width: none;
  }
`;

export const ServiceContainer = styled.div`
  margin-top: 32px;
`;

export const ServicesCategoryContainer = styled.div`
  transition: all 0.2s ease-in-out;
  &:hover {
    scale: 1.1;
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    img {
      box-shadow: 0 0 15px #d35400;
    }
  }
`;
