import React from "react";
import {
  Container,
  NavItem,
  SidebarWrapper,
  SubcategoryWrapper,
} from "./NavbarStyles";
import { StyledMedium, StyledSmall, StyledXL } from "../../styles/CommonStyles";
import { screenWidth } from "../utilities";

const Sidebar = ({ navbarData }) => {
  const screen = screenWidth();

  return (
    <SidebarWrapper className="isHidden">
      <Container>
        {navbarData?.map((navContent, index) => {
          return (
            <NavItem>
              <StyledMedium
                style={{
                  color: "white",
                  marginLeft: screen == "mobile" && "8px",
                }}
              >
                {navContent?.display_name}
              </StyledMedium>
              {navContent?.sub_categories?.length > 0 ? (
                <SubcategoryWrapper>
                  {navContent?.sub_categories?.map((subcategories) => {
                    return (
                      <StyledSmall style={{ color: "white", padding: "6px" }}>
                        {subcategories?.display_name}
                      </StyledSmall>
                    );
                  })}
                </SubcategoryWrapper>
              ) : null}
            </NavItem>
          );
        })}
      </Container>
    </SidebarWrapper>
  );
};

export default Sidebar;
