import styled from "styled-components";

export const CartProductWrapper = styled.div`
  display: flex;
  gap: 12px;
  flex-wrap;

`;
export const MultipleImagesContainer = styled.div`
  width: 15%;
  border-right: 1px solid #d3d3d3;
  height: 68vh;
  overflow-y: scroll;
  scrollbar-width: none;
`;
export const MainImageContainer = styled.div`
  width: 100%;
  height: 400px;
  cursor: pointer;
  transition: 0.8s all ease-in-out;
  margin: 16px 0px;
  img {
    width: 100%;
    height: 100%;
    border-radius: 12px;
  }
`;
export const ProductDescriptionContainer = styled.div`
  width: 37.5%;
  padding-right: 44px;
  margin-left: 100px;
`;

export const CartMultiImageWrapper = styled.div(({}) => ({
  width: "94%",
  height: "160px",
  cursor: "pointer",
  transition: "0.8s all ease-in-out",

  "&:hover": {
    transform: "scale(1.2)",
    transition: "0.5s all ease-in-out",
  },
  img: {
    width: "100%",
    height: "100%",
    borderRadius: "12px",
  },
}));

export const CartSideImageContainer = styled.div`
  margin: 12px 16px;
`;

export const Decription = styled.div`
  margin: 40px auto;
`;

export const RangerVerifiedContainer = styled.div`
  margin: 40px auto;
  background: #fee715ff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  gap: 12px;
`;

export const VerifiedContainer = styled.div`
  height: 50px;
  width: 50px;

  img {
    height: 100%;
    width: 100%;
  }
`;

export const SellerInformationContainer = styled.div``;

export const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0px;
  &.style-explore-btn {
    margin-top: 24px;
  }
`;

export const StarContainer = styled.div`
  display: flex;
`;

export const MoreCartProducts = styled.div`
  display: flex;
  gap: 44px;
  justify-content: flex-start;
  align-items: center;
  margin: 0px 24px;
`;

export const SuggestedProductContainer = styled.div`
  display: flex;
  flex-direction: column;s
  justify-content: center;
  align-items: center;
  transition: 0.2s all ease-in;

  &:hover {
    transform: scale(1.1);
    transition: 0.2s all ease-in;
    cursor: pointer;
  }
`;

export const SuggestedImage = styled.div`
  display: flex;
  height: 200px;
  width: 100%;
  img {
    height: 100%;
    width: 100%;
    border-radius: 12px;
  }
`;

export const SuggestedName = styled.div`
  display: flex;
`;

export const SuggestPrice = styled.div`
  display: flex;
`;

export const Title = styled.div`
  display: flex;
  margin: 20px;
`;
