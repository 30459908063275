import styled from "styled-components";

export const ProductCardWrapper = styled.div`
  cursor: pointer;
  position: relative;
  left: 120px;

  @media screen and (max-width: 768px) {
    left: 24px;
  }
`;

export const ProductImage = styled.div`
  height: 200px;
  width: 180px;

  img {
    height: 100%;
    width: 100%;
    border-radius: 12px;
  }
`;

export const ProductListings = styled.div``;
