import React, { useState } from "react";
import { Container } from "../../styles/CommonStyles";
import {
  PartnerButtonWrapper,
  PremiumRightArrow,
} from "../../styles/PremiumMembersStyles";
import { HiMiniArrowRight } from "react-icons/hi2";
import { screenWidth } from "../utilities";

const PartnerButton = () => {
  const [changeColor, setChangeColor] = useState(false);
  const screen = screenWidth();

  return (
    <Container
      className="dFlex"
      onMouseOver={() => setChangeColor(true)}
      onMouseOut={() => setChangeColor(false)}
    >
      <PartnerButtonWrapper>
        {screen !== "mobile" ? (
          <PremiumRightArrow>
            <HiMiniArrowRight
              size={36}
              color={changeColor ? "white" : "black"}
              className="premium-right-arrow"
            />
          </PremiumRightArrow>
        ) : null}
        Become a partner
      </PartnerButtonWrapper>
    </Container>
  );
};

export default PartnerButton;
