import React from "react";
import Filter from "../Filter/Filter";
import ClientProductListing from "../ClientsProductListing/ClientProductListing";
import { ClientProductContainer } from "./ClientProductFirstFoldStyles";
import ClientFilter from "../ClientFilter/ClientFilter";
import { useSelector } from "react-redux";

const ClientProductFirstFold = ({ client }) => {
  const { client_response_schema } = useSelector(
    (state) => state.HomepageReducer
  );

  return (
    <ClientProductContainer>
      <ClientFilter client={client_response_schema} />
      <ClientProductListing client={client_response_schema} />
    </ClientProductContainer>
  );
};

export default ClientProductFirstFold;
