import styled from "styled-components";

export const NavbarWrapper = styled.nav(({ backgroundColor }) => ({
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
  backgroundColor: backgroundColor,
  height: "104px",
  width: "100%",
  position: "sticky",
  top: 0,
  zIndex: "10",
}));

export const LogoContainer = styled.div`
  width: 140px;
  object-fit: contain;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
  }
`;

export const NavContent = styled.div`
  display: flex;
  gap: 24px;
  filter: brightness(1);
  object-fit: contain;
  position: relative;
  bottom: -27px;
  height: 44px;
  img {
    width: 100%;
    height: 100%;
  }
`;

export const NavChild = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
  gap: 12px;

  &.direction {
    flex-direction: column;
  }
`;

export const CompanyInfoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;

  &.align-social-icons {
    margin-right: 16px;
  }
`;

export const CTAIconsContainer = styled.div`
  display: flex;
  position: relative;
`;

export const NavItem = styled.div`
  cursor: pointer;
`;

export const LogoWrapper = styled.div`
  width: 150px;
  object-fit: contain;
  img {
    width: 100%;
    height: 100%;
  }
`;

export const MegaMenuWrapper = styled.div`
  position: absolute;
  background: white;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
  min-height: 360px;
  max-height: 500px;
  width: 100%;
  margin: 0px auto;
  display: none;
  z-index: 10;
  transition: all 0.5s ease-in;
  &.showCard {
    display: flex;
    justify-content: space-evenly;
    padding: 24px 32px;
    transition: all 1.5s ease-in-out;
  }
`;

export const MenuContentWrapper = styled.div`
  z-index: 1;
  display: flex;
  justify-content: space-evenly;

  &.nav-menu-style {
    gap: 24px;
    width: 60%;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  &.menu-card-style {
    flex-direction: column;
  }
`;

export const AdjustMenuContentWidth = styled.div`
  width: 160px;
`;

export const MegaMenuSearchContainer = styled.div``;

export const NavbarImageContainer = styled.div`
  height: 240px;
  width: 240px;
  position: absolute;
  right: 54px;
  top: 24px;
  img {
    height: 100%;
    width: 100%;
    border-radius: 24px;
  }
`;

export const InputBox = styled.input``;
export const NavbarFooter = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 24px 0px;
  background: #fee715ff;
  color: black;
`;

export const ClientInputContainer = styled.div``;
