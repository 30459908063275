import React, { useState } from "react";
import { Container } from "../../styles/CommonStyles";
import {
  PartnerButtonWrapper,
  PremiumRightArrow,
  ShopNowButtonWrapper,
} from "../../styles/PremiumMembersStyles";
import { HiMiniArrowRight } from "react-icons/hi2";

const ShopNowButton = ({ text, arrow, onClick }) => {
  const [changeColor, setChangeColor] = useState(false);

  return (
    <Container className="dFlex">
      <ShopNowButtonWrapper
        onMouseOver={() => setChangeColor(true)}
        onMouseOut={() => setChangeColor(false)}
        onClick={onClick}
      >
        {text}
        <Container className="dFlex" style={{ justifyContent: "initial" }}>
          {arrow ? (
            <HiMiniArrowRight
              size={20}
              color={changeColor ? "white" : "black"}
              className="shop-now-right-arrow"
            />
          ) : null}
        </Container>
      </ShopNowButtonWrapper>
    </Container>
  );
};

export default ShopNowButton;
